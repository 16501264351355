/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { NotificationItemFragmentDoc } from './notification.fragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetAllNotificationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetAllNotificationsQuery = {
  __typename?: 'query_root';
  user_notifications: Array<{
    __typename?: 'user_notifications';
    id: string;
    read_at?: string;
    archived_at?: string;
    created_at: string;
    content: {
      __typename?: 'notifications';
      type: Types.Notification_Types_Enum;
      params: ClientTypes.NotificationParams;
      control?: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
      notifications_comment?: {
        __typename?: 'comments';
        id: string;
        comments_control?: {
          __typename?: 'controls';
          id: string;
          name?: string;
          internal_id?: string;
        };
        comments_task?: { __typename?: 'tasks'; id: string; name?: string; internal_id?: string };
        comments_risk?: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
        comments_vendor?: {
          __typename?: 'vendors';
          id: string;
          name?: string;
          internal_id?: string;
        };
        comments_policy?: {
          __typename?: 'policies';
          id: string;
          name?: string;
          internal_id?: string;
        };
        questionnaire?: {
          __typename?: 'questionnaires';
          id: string;
          name?: string;
          internal_id?: string;
        };
        vendor_questionnaire?: {
          __typename?: 'vendor_questionnaires';
          id: string;
          questionnaire: {
            __typename?: 'questionnaires';
            id: string;
            name?: string;
            internal_id?: string;
          };
        };
        client_questionnaire_question?: {
          __typename?: 'client_questionnaire_questions';
          id: string;
          question: string;
          client_questionnaire: {
            __typename?: 'client_questionnaires';
            id: string;
            name?: string;
            internal_id?: string;
          };
        };
      };
      task?: { __typename?: 'tasks'; id: string; name?: string; internal_id?: string };
      policy?: { __typename?: 'policies'; id: string; name?: string; internal_id?: string };
      policy_version?: {
        __typename?: 'policy_versions';
        id: string;
        policy: { __typename?: 'policies'; id: string; name?: string; internal_id?: string };
      };
      notifications_risk?: {
        __typename?: 'risks';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_vendor?: {
        __typename?: 'vendors';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_evidence?: {
        __typename?: 'evidences';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_vendor_questionnaire?: {
        __typename?: 'vendor_questionnaires';
        id: string;
        sent_to_email: string;
        vendor: { __typename?: 'vendors'; id: string; name?: string };
        questionnaire: { __typename?: 'questionnaires'; name?: string };
      };
      client_questionnaire?: {
        __typename?: 'client_questionnaires';
        id: string;
        internal_id?: string;
        name?: string;
      };
    };
  }>;
};

export type GetInboxNotificationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetInboxNotificationsQuery = {
  __typename?: 'query_root';
  user_notifications: Array<{
    __typename?: 'user_notifications';
    id: string;
    read_at?: string;
    archived_at?: string;
    created_at: string;
    content: {
      __typename?: 'notifications';
      type: Types.Notification_Types_Enum;
      params: ClientTypes.NotificationParams;
      control?: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
      notifications_comment?: {
        __typename?: 'comments';
        id: string;
        comments_control?: {
          __typename?: 'controls';
          id: string;
          name?: string;
          internal_id?: string;
        };
        comments_task?: { __typename?: 'tasks'; id: string; name?: string; internal_id?: string };
        comments_risk?: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
        comments_vendor?: {
          __typename?: 'vendors';
          id: string;
          name?: string;
          internal_id?: string;
        };
        comments_policy?: {
          __typename?: 'policies';
          id: string;
          name?: string;
          internal_id?: string;
        };
        questionnaire?: {
          __typename?: 'questionnaires';
          id: string;
          name?: string;
          internal_id?: string;
        };
        vendor_questionnaire?: {
          __typename?: 'vendor_questionnaires';
          id: string;
          questionnaire: {
            __typename?: 'questionnaires';
            id: string;
            name?: string;
            internal_id?: string;
          };
        };
        client_questionnaire_question?: {
          __typename?: 'client_questionnaire_questions';
          id: string;
          question: string;
          client_questionnaire: {
            __typename?: 'client_questionnaires';
            id: string;
            name?: string;
            internal_id?: string;
          };
        };
      };
      task?: { __typename?: 'tasks'; id: string; name?: string; internal_id?: string };
      policy?: { __typename?: 'policies'; id: string; name?: string; internal_id?: string };
      policy_version?: {
        __typename?: 'policy_versions';
        id: string;
        policy: { __typename?: 'policies'; id: string; name?: string; internal_id?: string };
      };
      notifications_risk?: {
        __typename?: 'risks';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_vendor?: {
        __typename?: 'vendors';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_evidence?: {
        __typename?: 'evidences';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_vendor_questionnaire?: {
        __typename?: 'vendor_questionnaires';
        id: string;
        sent_to_email: string;
        vendor: { __typename?: 'vendors'; id: string; name?: string };
        questionnaire: { __typename?: 'questionnaires'; name?: string };
      };
      client_questionnaire?: {
        __typename?: 'client_questionnaires';
        id: string;
        internal_id?: string;
        name?: string;
      };
    };
  }>;
};

export type GetArchivedNotificationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetArchivedNotificationsQuery = {
  __typename?: 'query_root';
  user_notifications: Array<{
    __typename?: 'user_notifications';
    id: string;
    read_at?: string;
    archived_at?: string;
    created_at: string;
    content: {
      __typename?: 'notifications';
      type: Types.Notification_Types_Enum;
      params: ClientTypes.NotificationParams;
      control?: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
      notifications_comment?: {
        __typename?: 'comments';
        id: string;
        comments_control?: {
          __typename?: 'controls';
          id: string;
          name?: string;
          internal_id?: string;
        };
        comments_task?: { __typename?: 'tasks'; id: string; name?: string; internal_id?: string };
        comments_risk?: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
        comments_vendor?: {
          __typename?: 'vendors';
          id: string;
          name?: string;
          internal_id?: string;
        };
        comments_policy?: {
          __typename?: 'policies';
          id: string;
          name?: string;
          internal_id?: string;
        };
        questionnaire?: {
          __typename?: 'questionnaires';
          id: string;
          name?: string;
          internal_id?: string;
        };
        vendor_questionnaire?: {
          __typename?: 'vendor_questionnaires';
          id: string;
          questionnaire: {
            __typename?: 'questionnaires';
            id: string;
            name?: string;
            internal_id?: string;
          };
        };
        client_questionnaire_question?: {
          __typename?: 'client_questionnaire_questions';
          id: string;
          question: string;
          client_questionnaire: {
            __typename?: 'client_questionnaires';
            id: string;
            name?: string;
            internal_id?: string;
          };
        };
      };
      task?: { __typename?: 'tasks'; id: string; name?: string; internal_id?: string };
      policy?: { __typename?: 'policies'; id: string; name?: string; internal_id?: string };
      policy_version?: {
        __typename?: 'policy_versions';
        id: string;
        policy: { __typename?: 'policies'; id: string; name?: string; internal_id?: string };
      };
      notifications_risk?: {
        __typename?: 'risks';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_vendor?: {
        __typename?: 'vendors';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_evidence?: {
        __typename?: 'evidences';
        id: string;
        name?: string;
        internal_id?: string;
      };
      notifications_vendor_questionnaire?: {
        __typename?: 'vendor_questionnaires';
        id: string;
        sent_to_email: string;
        vendor: { __typename?: 'vendors'; id: string; name?: string };
        questionnaire: { __typename?: 'questionnaires'; name?: string };
      };
      client_questionnaire?: {
        __typename?: 'client_questionnaires';
        id: string;
        internal_id?: string;
        name?: string;
      };
    };
  }>;
};

export type GetUnreadNotificationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
}>;

export type GetUnreadNotificationsQuery = {
  __typename?: 'query_root';
  user_notifications_aggregate: {
    __typename?: 'user_notifications_aggregate';
    aggregate?: { __typename?: 'user_notifications_aggregate_fields'; count: number };
  };
};

export const GetAllNotificationsDocument = `
    query GetAllNotifications($userId: uuid!, $limit: Int = 10, $offset: Int = 0) {
  user_notifications(
    limit: $limit
    offset: $offset
    where: {user_id: {_eq: $userId}, delivery_type: {_eq: platform}}
    order_by: {created_at: desc}
  ) {
    ...NotificationItem
  }
}
    ${NotificationItemFragmentDoc}` as string &
  TypedDocumentNode<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>;
export const GetInboxNotificationsDocument = `
    query GetInboxNotifications($userId: uuid!, $limit: Int = 10, $offset: Int = 0) {
  user_notifications(
    limit: $limit
    offset: $offset
    where: {user_id: {_eq: $userId}, delivery_type: {_eq: platform}, archived_at: {_is_null: true}}
    order_by: {created_at: desc}
  ) {
    ...NotificationItem
  }
}
    ${NotificationItemFragmentDoc}` as string &
  TypedDocumentNode<GetInboxNotificationsQuery, GetInboxNotificationsQueryVariables>;
export const GetArchivedNotificationsDocument = `
    query GetArchivedNotifications($userId: uuid!, $limit: Int = 10, $offset: Int = 0) {
  user_notifications(
    limit: $limit
    offset: $offset
    where: {user_id: {_eq: $userId}, delivery_type: {_eq: platform}, archived_at: {_is_null: false}}
    order_by: {created_at: desc}
  ) {
    ...NotificationItem
  }
}
    ${NotificationItemFragmentDoc}` as string &
  TypedDocumentNode<GetArchivedNotificationsQuery, GetArchivedNotificationsQueryVariables>;
export const GetUnreadNotificationsDocument = `
    query GetUnreadNotifications($userId: uuid!) {
  user_notifications_aggregate(
    where: {user_id: {_eq: $userId}, read_at: {_is_null: true}, archived_at: {_is_null: true}, delivery_type: {_eq: platform}}
  ) {
    aggregate {
      count
    }
  }
}
    ` as string &
  TypedDocumentNode<GetUnreadNotificationsQuery, GetUnreadNotificationsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAllNotifications: build.query<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>({
      query: (variables) => ({ document: GetAllNotificationsDocument, variables }),
    }),
    GetInboxNotifications: build.query<
      GetInboxNotificationsQuery,
      GetInboxNotificationsQueryVariables
    >({
      query: (variables) => ({ document: GetInboxNotificationsDocument, variables }),
    }),
    GetArchivedNotifications: build.query<
      GetArchivedNotificationsQuery,
      GetArchivedNotificationsQueryVariables
    >({
      query: (variables) => ({ document: GetArchivedNotificationsDocument, variables }),
    }),
    GetUnreadNotifications: build.query<
      GetUnreadNotificationsQuery,
      GetUnreadNotificationsQueryVariables
    >({
      query: (variables) => ({ document: GetUnreadNotificationsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAllNotificationsQuery,
  useLazyGetAllNotificationsQuery,
  useGetInboxNotificationsQuery,
  useLazyGetInboxNotificationsQuery,
  useGetArchivedNotificationsQuery,
  useLazyGetArchivedNotificationsQuery,
  useGetUnreadNotificationsQuery,
  useLazyGetUnreadNotificationsQuery,
} = injectedRtkApi;
