import { breakingChangeApi } from '@main/breaking-change-detector';
import {
  bulkDownloaderListenerMiddleware,
  BulkDownloaderPath,
  bulkDownloaderSlice,
  bulkDownloadStarted,
  startBulkDownload,
  WithBulkDownloaderReducer,
} from '@main/bulk-downloader';
import { api, RequiredGraphqlState, WithGraphqlReducer } from '@main/graphql/shared-api';
import { NHOST_REFRESH_TOKEN_KEY } from '@nhost/nhost-js';
import { combineReducers, configureStore, createReducer, Reducer } from '@reduxjs/toolkit';

import { featureFlagsReducer } from '../features/feature-flags/slice';
import { programListenerMiddleware } from '../features/program/middleware';
import { userListenerMiddleware } from '../features/user/middleware';
import {
  authenticatedUserIdReceived,
  userReducer,
  userSessionInvalidated,
} from '../features/user/slice';
import { nhost } from '../utils/nhostClient';

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [breakingChangeApi.reducerPath]: breakingChangeApi.reducer,
  me: userReducer,
  featureFlags: featureFlagsReducer,
  gql: createReducer<RequiredGraphqlState>({ nhostClient: nhost, defaultRole: 'user' }, (builder) =>
    builder.addCase(authenticatedUserIdReceived, (state) => {
      state.defaultRole = 'user';
    }),
  ),
  ...bulkDownloaderSlice,
} satisfies WithGraphqlReducer & WithBulkDownloaderReducer);

window.addEventListener('storage', (event) => {
  if (event.key === NHOST_REFRESH_TOKEN_KEY && !localStorage.getItem(NHOST_REFRESH_TOKEN_KEY)) {
    window.location.reload();
  }
});

const rootReducer: Reducer<ReturnType<typeof appReducer>> = (state, action) => {
  if (userSessionInvalidated.match(action)) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['gql', BulkDownloaderPath],
        ignoredActions: [startBulkDownload.type, bulkDownloadStarted.type],
      },
    }).concat([
      api.middleware,
      breakingChangeApi.middleware,
      userListenerMiddleware.middleware,
      programListenerMiddleware.middleware,
      bulkDownloaderListenerMiddleware.middleware,
    ]),
  devTools: import.meta.env.DEV,
});

export type AppRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
