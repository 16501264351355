import { Box, Text, VStack } from '@chakra-ui/react';
import { useStableCallback } from '@main/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PaginatedHistoryList,
  useGetUserNameForAction,
  UserAction,
} from '../activity-history/shared';
import {
  ClientQquestionAnswerHistoryFragment,
  useGetClientQquestionHistoryQuery,
} from './question-history-tab.generated';

export type ClientQquestionHistoryActivity = ClientQquestionAnswerActivity;

export interface ClientQquestionAnswerActivity extends ClientQquestionAnswerHistoryFragment {
  type: 'answer-updated';
  updateType: 'answer' | 'comment' | 'both';
  table_name: 'clinet_quesitonnaire_answers';
  action_timestamp: string | undefined;
}

export function QuestionHistoryTab({ questionId }: { questionId: string }) {
  const [paginationParams, setPagination] = useState<{ limit: number; offset: number }>();
  const { data, isLoading } = useGetClientQquestionHistoryQuery(
    { questionId, ...paginationParams },
    { refetchOnMountOrArgChange: true },
  );
  const activity: ClientQquestionHistoryActivity[] = useMemo(
    () =>
      data?.question?.answers.map(
        (answer, i) =>
          ({
            ...answer,
            type: 'answer-updated',
            updateType: getUpdateType(answer, data?.question?.answers[i + 1]),
            table_name: 'clinet_quesitonnaire_answers',
            action_timestamp: answer.created_at,
          }) as const,
      ) ?? [],
    [data?.question?.answers],
  );

  const getActivityNode = useStableCallback((activity: ClientQquestionHistoryActivity) => {
    switch (activity.type) {
      case 'answer-updated': {
        return <ClientQquestionAnswerUpdatedActivity activity={activity} />;
      }
    }
  });

  return (
    <Box overflowX="auto">
      <PaginatedHistoryList
        currentPageData={activity}
        isLoadingHistory={isLoading}
        setPaginationParams={setPagination}
        getActivityNode={getActivityNode}
      />
    </Box>
  );
}

function ClientQquestionAnswerUpdatedActivity({
  activity,
}: {
  activity: ClientQquestionAnswerActivity;
}) {
  const { t } = useTranslation();
  const getUserName = useGetUserNameForAction();
  const user = activity.updatedBy
    ? ({ 'x-hasura-user-id': activity.updatedBy.id, 'x-hasura-role': 'user' } as const)
    : ({ 'x-hasura-user-id': '', 'x-hasura-role': 'admin' } as const);

  return (
    <UserAction hasuraUser={user} timestamp={activity.created_at}>
      <VStack alignItems="flex-start">
        <Box fontSize="sm">
          <Text as="strong" flexShrink="0" pr="2">
            {getUserName(user)}
          </Text>
          {activity.updateType === 'comment' ? (
            <>
              <Text as="span">{`${t('clientQquestionDrawer.history.commentUpdated')} `}</Text>
              <Text as="span" fontWeight="semibold">
                {activity.comment}
              </Text>
            </>
          ) : (
            <>
              <Text as="span">{`${t('clientQquestionDrawer.history.answerUpdated')} `}</Text>
              <Text as="span" fontWeight="semibold">
                {activity.answer}
              </Text>
            </>
          )}
        </Box>
        {activity.updateType === 'both' && (
          <Box fontSize="sm">
            <Text as="span">
              {`${t('clientQquestionDrawer.history.and')} `}
              {`${t('clientQquestionDrawer.history.commentUpdated')} `}
            </Text>
            <Text as="span" fontWeight="semibold">
              {activity.comment}
            </Text>
          </Box>
        )}
      </VStack>
    </UserAction>
  );
}

function getUpdateType(
  answer: ClientQquestionAnswerHistoryFragment,
  prevAnswer?: ClientQquestionAnswerHistoryFragment,
): ClientQquestionAnswerActivity['updateType'] {
  if (
    !prevAnswer ||
    (answer.answer !== prevAnswer.answer && answer.comment !== prevAnswer.comment)
  ) {
    return 'both';
  }

  if (answer.answer !== prevAnswer.answer) {
    return 'answer';
  }

  return 'comment';
}
