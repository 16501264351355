/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { CommentsFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/comments.fragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetClientQquestionCommentsTabQueryVariables = Types.Exact<{
  questionId: Types.Scalars['uuid']['input'];
}>;

export type GetClientQquestionCommentsTabQuery = {
  __typename?: 'query_root';
  question?: {
    __typename?: 'client_questionnaire_questions';
    comments: Array<{
      __typename?: 'comments';
      id: string;
      text: string;
      created_at: string;
      updated_at: string;
      user_id: string;
      comments_comment_uploads: Array<{
        __typename?: 'comment_uploads';
        id: string;
        comment_uploads_file: {
          __typename?: 'files';
          id: string;
          name?: string;
          size?: number;
          createdAt: string;
        };
      }>;
    }>;
  };
};

export const GetClientQquestionCommentsTabDocument = `
    query GetClientQquestionCommentsTab($questionId: uuid!) {
  question: client_questionnaire_questions_by_pk(id: $questionId) {
    comments {
      ...CommentsFragment
    }
  }
}
    ${CommentsFragmentFragmentDoc}` as string &
  TypedDocumentNode<
    GetClientQquestionCommentsTabQuery,
    GetClientQquestionCommentsTabQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetClientQquestionCommentsTab: build.query<
      GetClientQquestionCommentsTabQuery,
      GetClientQquestionCommentsTabQueryVariables
    >({
      query: (variables) => ({ document: GetClientQquestionCommentsTabDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetClientQquestionCommentsTabQuery, useLazyGetClientQquestionCommentsTabQuery } =
  injectedRtkApi;
