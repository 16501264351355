import { formatDateTime } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { getCurrentOrgUsersMap } from '../../../user/slice';
import { ControlFindingManual } from '../control-finding';
import { FindingAdditionalDetails, FindingDescription, FindingHeading } from './shared';

export const ContentManual = ({
  title,
  description,
  createdBy,
  createdAt,
}: ControlFindingManual) => {
  const { t } = useTranslation();

  const orgUsers = useAppSelector(getCurrentOrgUsersMap);
  const createdByUser = createdBy ? orgUsers[createdBy] : null;
  const createdByText = createdByUser
    ? t('findings.cause.manual.reportedByUser', {
        userName: createdByUser.displayName,
        date: formatDateTime(createdAt),
      })
    : t('findings.cause.manual.reportedByUnknown', {
        date: formatDateTime(createdAt),
      });

  return (
    <>
      <FindingHeading>{title}</FindingHeading>
      {description && <FindingDescription>{description}</FindingDescription>}
      <FindingAdditionalDetails>{createdByText}</FindingAdditionalDetails>
    </>
  );
};
