import { createAction, createReducer } from '@reduxjs/toolkit';

import type { AppRootState } from '../../store';

export type FeatureFlagsState = Record<string, string | boolean>;

const initialState: FeatureFlagsState = {};

export const featureFlagsUpdated = createAction<FeatureFlagsState>('[feature flags] updated');

export const featureFlagsReducer = createReducer(initialState, (builder) => {
  builder.addCase(featureFlagsUpdated, (_state, action) => action.payload);
});

export const isFeatureFlagEnabled = (name: string) => (state: AppRootState) => {
  return Boolean(state.featureFlags[name]);
};

export function getFeatureFlags(state: AppRootState) {
  return state.featureFlags;
}
