import {
  Avatar,
  Box,
  Flex,
  Spacer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { CalendarDaysIcon, TrashIcon } from '@heroicons/react/24/outline';
import { formatDate, isNonNullable, toError, typedObjectEntries } from '@main/shared/utils';
import {
  FloatingActionButtons,
  FloatingButtonsContainer,
  getHashedAvatarColor,
  StatusTag,
  useDrawer,
} from '@main/ui';
import dayjs from 'dayjs';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentOrgUsersMap, getCurrentUserId } from '../../user/slice';
import { TASK_STATUSES } from '../constants';
import { useDeleteTask } from '../delete-task';
import { LinkedEntityObject } from '../slice';
import {
  useLazyInProgressOrPendingToggleMenuAction,
  useLazyPendingOrCompleteToggleAction,
} from '../use-drawer-actions';
import { api as getTaskApi, TaskSidebarDataFragment } from './manage-task-sidebar.generated';

type Task = TaskSidebarDataFragment & {
  linkedEntities: LinkedEntityObject;
};

export const TaskSidebarItem = ({
  task,
  onSelect: closeSidebar,
  hideAssignee = false,
  onToggleComplete,
  onToggleInProgress,
  onDelete,
}: {
  task: Task;
  onSelect: () => void;
  hideAssignee?: boolean;
  onToggleComplete: () => void;
  onToggleInProgress: () => void;
  onDelete: () => void;
}) => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const taskNameColor = useColorModeValue('gray.600', 'gray.400');
  const namePlaceholderColor = useColorModeValue('gray.400', 'gray.600');
  const dateTextColor = useColorModeValue('gray.400', 'gray.500');
  const bottomBorderColor = useColorModeValue('gray.200', 'gray.500');
  const dotColor = useColorModeValue('gray.400', 'gray.500');

  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const taskOwnerId = task.task_owners[0]?.owner_id;

  const markPendingOrComplete = useLazyPendingOrCompleteToggleAction()({
    taskId: task.id,
    completedAt: task.completed_at,
  });
  const markInProgressOrPendingToggle = useLazyInProgressOrPendingToggleMenuAction()({
    taskId: task.id,
    taskStatus: task.status,
  });
  const deleteTask = useDeleteTask();
  const refetchTasks = useRefetchTasks();

  const linkedEntities = typedObjectEntries(task.linkedEntities)
    .map(([entityName, entities]) => (entities.length === 0 ? null : entityName))
    .filter(isNonNullable);

  return (
    <FloatingButtonsContainer
      position="relative"
      cursor="pointer"
      px={6}
      py={5}
      borderBottom="1px"
      borderColor={bottomBorderColor}
      onClick={() => {
        drawer.open({ entity: 'task', entityId: task.id });
        closeSidebar();
      }}
    >
      <Flex direction="column" gap={1}>
        <Flex gap={1} alignItems="center">
          <StatusTag
            size="sm"
            minW="auto"
            maxW="none"
            h="fit-content"
            colorScheme={TASK_STATUSES[task.status].colorScheme}
            my={2}
          >
            {t(`tasks.enum.status.${task.status}`)}
          </StatusTag>
          {task.due_date && (
            <Tag size="sm" h="fit-content">
              <TagLeftIcon boxSize="12px" as={CalendarDaysIcon} />
              <TagLabel>{formatDate(task.due_date, 'll')}</TagLabel>
            </Tag>
          )}
          <Spacer />
          {taskOwnerId && !hideAssignee ? (
            <Avatar
              size="sm"
              name={currentOrgUsers[taskOwnerId]?.displayName}
              {...getHashedAvatarColor(currentOrgUsers[taskOwnerId]?.displayName)}
            />
          ) : null}
        </Flex>

        {task.name ? (
          <Text fontSize="sm" fontWeight="semibold" color={taskNameColor}>
            {task.name}
          </Text>
        ) : (
          <Text fontSize="sm" fontWeight="semibold" color={namePlaceholderColor}>
            {t('tasks.name')}
          </Text>
        )}

        <Flex gap={2} flexWrap="wrap" alignItems="center" fontSize="xs" textColor={dateTextColor}>
          {linkedEntities.length !== 0 ? (
            <>
              <Flex gap={1} flexWrap="wrap">
                {linkedEntities.map((entity, index) => {
                  return (
                    <Fragment key={index}>
                      {index !== 0 && <Text>–</Text>}
                      <Text>{t(`entities.${entity}`)}</Text>
                    </Fragment>
                  );
                })}
              </Flex>
              <Box bgColor={dotColor} w={1} h={1} rounded="full" />
            </>
          ) : null}

          <Text fontSize="xs" textColor={dateTextColor}>
            {t('tasks.sidebar.lastUpdatedTime', { time: dayjs(task.updated_at).fromNow(true) })}
          </Text>
        </Flex>
      </Flex>
      <FloatingActionButtons
        position="absolute"
        top={2}
        right={2}
        buttons={[
          markPendingOrComplete
            ? {
                'aria-label': markPendingOrComplete.label,
                icon: markPendingOrComplete.icon,
                tooltip: markPendingOrComplete.label,
                isLoading: markPendingOrComplete.isLoading,
                onClick: async () => {
                  await markPendingOrComplete.onClick();
                  onToggleComplete();
                },
              }
            : null,
          markInProgressOrPendingToggle
            ? {
                'aria-label': markInProgressOrPendingToggle.label,
                icon: markInProgressOrPendingToggle.icon,
                tooltip: markInProgressOrPendingToggle.label,
                isLoading: markInProgressOrPendingToggle.isLoading,
                onClick: async () => {
                  await markInProgressOrPendingToggle.onClick();
                  onToggleInProgress();
                },
              }
            : null,
          deleteTask
            ? {
                'aria-label': 'Delete',
                icon: <TrashIcon />,
                tooltip: t('buttons.delete'),
                onClick: () => {
                  deleteTask(task.id, refetchTasks);
                  onDelete();
                },
              }
            : null,
        ].filter(isNonNullable)}
      />
    </FloatingButtonsContainer>
  );
};

export const useRefetchTasks = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getCurrentUserId);

  return useCallback(() => {
    try {
      dispatch(
        getTaskApi.endpoints.GetAssignedToMeTasks.initiate(
          {
            userId,
          },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );

      dispatch(
        getTaskApi.endpoints.GetCreatedByMeTasks.initiate(
          {
            creatorId: userId,
          },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );
    } catch (error) {
      datadogLogs.logger.error('Failed to refetch sidebar tasks', {}, toError(error));
    }
  }, [dispatch, userId]);
};
