/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateManualFindingMutationVariables = Types.Exact<{
  input: Types.CreateManualFindingInput;
}>;

export type CreateManualFindingMutation = {
  __typename?: 'mutation_root';
  create_manual_finding?: { __typename?: 'CreateManualFindingOutput'; findingId: string };
};

export const CreateManualFindingDocument = `
    mutation CreateManualFinding($input: CreateManualFindingInput!) {
  create_manual_finding(input: $input) {
    findingId
  }
}
    ` as string &
  TypedDocumentNode<CreateManualFindingMutation, CreateManualFindingMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateManualFinding: build.mutation<
      CreateManualFindingMutation,
      CreateManualFindingMutationVariables
    >({
      query: (variables) => ({ document: CreateManualFindingDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateManualFindingMutation } = injectedRtkApi;
