import { Button, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { CreateEvidenceVersionModal } from '../../../evidence/evidence-versions';
import { useIsUserAuthorizedToChangeEvidence } from '../../../evidence/utils';
import { ControlFindingEvidenceStatus } from '../control-finding';
import { FindingDescription, FindingHeading } from './shared';

export const ContentEvidenceStatus = ({ cause, evidence }: ControlFindingEvidenceStatus) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const permission = useIsUserAuthorizedToChangeEvidence(evidence) ? 'write' : 'read';
  const linkColor = useColorModeValue('blue.600', 'blue.200');

  return (
    <>
      <FindingHeading>{t(`findings.cause.${cause}.title`)}</FindingHeading>
      <FindingDescription>
        <Trans
          i18nKey={`findings.cause.${cause}.reason.${permission}`}
          components={{
            a: (
              <Button
                fontSize="sm"
                fontWeight="normal"
                variant="link"
                color={linkColor}
                onClick={onOpen}
              />
            ),
          }}
          values={{
            evidenceName: evidence.name,
          }}
        />
      </FindingDescription>

      <CreateEvidenceVersionModal
        evidenceId={evidence.id}
        isOpen={isOpen}
        onCreate={onClose}
        onClose={onClose}
      />
    </>
  );
};
