/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { ControlDetailsFragmentDoc } from '../fragments/ControlDetailsFragment.generated';
import { FieldValueFragmentDoc } from '../fragments/FieldValueFragments.generated';
import { api } from '@main/graphql/shared-api';
export type GetOrganizationControlsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetOrganizationControlsQuery = {
  __typename?: 'query_root';
  controls: Array<{
    __typename?: 'controls';
    id: string;
    name?: string;
    description?: string;
    status: ClientTypes.ControlStatus;
    internal_id?: string;
    assignee_id?: string;
    frequency: Types.Control_Frequencies_Enum;
    priority?: Types.Control_Priorities_Enum;
    irrelevant: boolean;
    organization_id: string;
    findings: Array<{
      __typename?: 'control_findings';
      type: Types.Finding_Types_Enum;
      ignored_at?: string;
    }>;
    programs: Array<{ __typename?: 'program_controls'; program_id: string }>;
    groups: Array<{
      __typename?: 'control_groups';
      group: { __typename?: 'groups'; id: string; name: string };
    }>;
    criterias: Array<{
      __typename?: 'control_criteria';
      criteria: { __typename?: 'criteria'; id: string; name: string };
    }>;
    custom_tags: Array<{
      __typename?: 'control_tags';
      tag: { __typename?: 'tags'; id: string; name: string };
    }>;
    categories: Array<{
      __typename?: 'control_categories';
      category: { __typename?: 'categories'; id: string; name: string };
    }>;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
      date_field_value?: {
        __typename?: 'date_field_values';
        field_value_id: string;
        value: string;
      };
      number_field_value?: {
        __typename?: 'number_field_values';
        field_value_id: string;
        value: number;
      };
      email_field_value?: {
        __typename?: 'email_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  }>;
};

export const GetOrganizationControlsDocument = `
    query GetOrganizationControls($organizationId: uuid!) {
  controls(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {internal_id: asc}
  ) {
    ...ControlDetails
    findings {
      type
      ignored_at
    }
  }
}
    ${ControlDetailsFragmentDoc}
${FieldValueFragmentDoc}` as string &
  TypedDocumentNode<GetOrganizationControlsQuery, GetOrganizationControlsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganizationControls: build.query<
      GetOrganizationControlsQuery,
      GetOrganizationControlsQueryVariables
    >({
      query: (variables) => ({ document: GetOrganizationControlsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrganizationControlsQuery, useLazyGetOrganizationControlsQuery } =
  injectedRtkApi;
