import { useStableCallback } from '@main/shared/utils';
import { useMemo, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useStatefulCallback<TArgs extends any[], T>(cb: (...args: TArgs) => Promise<T>) {
  const [isLoading, setIsLoading] = useState(false);

  const callback = useStableCallback(async (...args: TArgs) => {
    setIsLoading(true);
    try {
      return await cb(...args);
    } finally {
      setIsLoading(false);
    }
  });

  return useMemo(() => ({ callback, isLoading }), [callback, isLoading]);
}
