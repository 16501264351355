import { ThemeTypings } from '@chakra-ui/react';
import { ControlEvidenceStatus } from '@main/graphql/client-scalars';
import { EvidenceProgramFragment } from '@main/graphql/fragments/EvidenceProgramFragment.generated';
import { api as getEvidenceApi } from '@main/graphql/queries/GetEvidenceById.generated';
import { api as getEvidenceVersionListApi } from '@main/graphql/queries/GetEvidenceVersionsListByEvidenceId.generated';
import { api as getOrganizationEvidencesApi } from '@main/graphql/queries/GetOrganizationEvidences.generated';
import { formatDate, isNonNullable, Severity } from '@main/shared/utils';
import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';

import { AppRootState } from '../../store';
import { getOrgProgramsMap, OrganizationProgram } from '../program/slice';
import {
  api as evidenceApi,
  GetEvidencePaginatedQuery,
  GetEvidencePaginatedQueryVariables,
} from './Evidence.generated';
import { EvidenceVersionType } from './evidence-versions/evidence-version-type';

type EvidenceVersion = ReturnType<typeof getEvidenceVersionsApiData>[number];

const emptyArray: never[] = [];

/* => Selectors <= */
const getEvidenceVersionsApiData = createSelector(
  (state: AppRootState, evidenceId?: string) => ({ evidenceId, state }),
  ({ state, evidenceId }) => {
    if (!evidenceId) return [];

    return (
      getEvidenceVersionListApi.endpoints.GetEvidenceVersionsListByEvidenceId.select({
        evidenceId,
      })(state).data?.evidence_versions || emptyArray
    );
  },
);

const getEvidenceApiData = createSelector(
  (state: AppRootState, evidenceId: string) => ({ evidenceId, state }),
  ({ state, evidenceId }) => {
    return getEvidenceApi.endpoints.GetEvidenceById.select({
      evidence_id: evidenceId,
    })(state).data?.evidences_by_pk;
  },
);

function mapVersion(version: EvidenceVersion) {
  const type = getEvidenceVersionType(version);

  return {
    id: version.id,
    fileName:
      (type === EvidenceVersionType.Link
        ? version.url
        : version.evidence_version_file?.file.name) || '',
    type,
    url: version.url,
    validityStart: formatDate(version.validity_start),
    createdBy: version.created_by,
    updatedAt: version.updated_at,
    fileId: version.evidence_version_file?.file?.id || null,
  };
}

export function getEvidenceVersionType(version: { policy_version_id?: string; url?: string }) {
  if (version.policy_version_id) {
    return EvidenceVersionType.Policy;
  }
  if (version.url) {
    return EvidenceVersionType.Link;
  }
  return EvidenceVersionType.File;
}

export type EvidenceVersionListItem = ReturnType<typeof mapVersion>;

export const getCurrentEvidenceVersion = createSelector(
  [getEvidenceVersionsApiData],
  (evidenceVersions) => {
    const currentVersion = evidenceVersions.find((version) => version.is_current);

    return currentVersion ? mapVersion(currentVersion) : null;
  },
);

export const getMappedEvidenceVersions = createSelector(
  [getEvidenceVersionsApiData, getCurrentEvidenceVersion],
  (evidenceVersions, currentVersion) => {
    const pastVersions = currentVersion
      ? evidenceVersions.filter((version) => version.id !== currentVersion?.id)
      : evidenceVersions;

    return {
      currentVersion,
      pastVersions: pastVersions.map((version) => mapVersion(version)),
    };
  },
);

export const getEvidenceVersion = createSelector(
  [
    getEvidenceVersionsApiData,
    (_: AppRootState, evidenceId: string, evidenceVersionId: string) => ({
      evidenceId,
      evidenceVersionId,
    }),
  ],
  (evidenceVersions, { evidenceVersionId }) => {
    return evidenceVersions.find((item) => item.id === evidenceVersionId);
  },
  // since we are returning new object everytime from the second selector
  {
    memoizeOptions: {
      equalityCheck: shallowEqual,
    },
  },
);

const getOrganizationEvidencesApiData = createSelector(
  (state: AppRootState, organizationId?: string) => ({ organizationId, state }),
  ({ state, organizationId }) => {
    if (!organizationId) return [];

    return (
      getOrganizationEvidencesApi.endpoints.GetOrganizationEvidences.select({
        organizationId,
      })(state).data?.evidences || emptyArray
    );
  },
);

export function mapAssociatedPrograms(
  evidence: EvidenceProgramFragment,
  programsMap: Map<string, OrganizationProgram>,
) {
  // if user has no permission to view controls, return empty array
  if (programsMap.size === 0) {
    return [];
  }

  // evidence can belong to multiple controls and different controls can belong to same program
  const evidenceProgramsWithDuplicates = evidence.control_evidences
    .flatMap(({ control }) => control.programs)
    .map(({ program_id }) => programsMap.get(program_id))
    .filter(isNonNullable);

  const uniqueById = new Map<string, (typeof evidenceProgramsWithDuplicates)[number]>();

  evidenceProgramsWithDuplicates.forEach((evidenceProgram) => {
    uniqueById.set(evidenceProgram?.id, evidenceProgram);
  });

  return Array.from(uniqueById.values());
}

export const getMappedEvidence = createSelector(
  [getEvidenceApiData, getOrgProgramsMap],
  (evidence, programsMap) => {
    if (!evidence) {
      return;
    }

    return {
      ...evidence,
      programs: mapAssociatedPrograms(evidence, programsMap),
    };
  },
);

export const getMappedOrganizationEvidences = createSelector(
  [getOrganizationEvidencesApiData, getOrgProgramsMap],
  (evidence, programsMap) => {
    return evidence.map((evidence) => mapOrgEvidence(evidence, programsMap));
  },
);

const getEvidencePaginatedApiData = createSelector(
  (state: AppRootState, variables: GetEvidencePaginatedQueryVariables) => ({
    state,
    variables,
  }),
  ({ state, variables }) => {
    return (
      evidenceApi.endpoints.GetEvidencePaginated.select(variables)(state).data?.evidences ||
      emptyArray
    );
  },
);

export const getMappedEvidencePaginated = createSelector(
  [getEvidencePaginatedApiData, getOrgProgramsMap],
  (evidence, programsMap) => {
    return evidence.map((evidence) => mapOrgEvidence(evidence, programsMap));
  },
);

function mapOrgEvidence(
  evidence: GetEvidencePaginatedQuery['evidences'][number],
  programsMap: Map<string, OrganizationProgram>,
) {
  const currentVersion = evidence.evidence_versions[0];
  return {
    ...evidence,
    currentVersion: {
      url: currentVersion?.url,
      file: currentVersion?.evidence_version_file?.file,
      validityStart: currentVersion?.validity_start,
      createdBy: currentVersion?.created_by,
      updatedAt: currentVersion?.updated_at,
    },
    programs: mapAssociatedPrograms(evidence, programsMap),
  };
}

export type OrganizationEvidence = ReturnType<typeof getMappedOrganizationEvidences>[number];

export type Option = {
  value: string;
  label: string;
  id: string;
  colorScheme?: ThemeTypings['colorSchemes'];
};

export const getEvidenceTags = createSelector([getEvidenceApiData], (evidence) => {
  if (!evidence) {
    return undefined;
  }

  return evidence.tags.map(({ id, tag }) => ({
    value: tag.id,
    label: tag.name,
    id,
    colorScheme: 'purple',
  }));
});

export const getEvidenceControlsSeverity = createSelector([getEvidenceApiData], (evidence) => {
  if (!evidence || !evidence.control_evidences.length) {
    return undefined;
  }

  const amountOfControls = evidence.control_evidences.length;
  const hasFailingControl = evidence.control_evidences.some(
    ({ status }) => status === ControlEvidenceStatus.INVALID,
  );
  const hasAtRiskControl = evidence.control_evidences.some(
    ({ status }) => status === ControlEvidenceStatus.AT_RISK,
  );

  if (hasFailingControl) {
    return {
      severity: Severity.HIGH,
      amount: amountOfControls,
    };
  }

  if (hasAtRiskControl) {
    return {
      severity: Severity.MEDIUM,
      amount: amountOfControls,
    };
  }

  return {
    severity: Severity.LOW,
    amount: amountOfControls,
  };
});
