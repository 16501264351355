/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetClientQuestionnairesTableQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid']['input'];
}>;

export type GetClientQuestionnairesTableQuery = {
  __typename?: 'query_root';
  client_questionnaires: Array<{
    __typename?: 'client_questionnaires';
    id: string;
    internal_id?: string;
    name?: string;
    status: Types.Client_Questionnaire_Status_Enum;
    updated_at: string;
    company: string;
    due_date: string;
    owner_id?: string;
    questions_total: {
      __typename?: 'client_questionnaire_questions_aggregate';
      aggregate?: { __typename?: 'client_questionnaire_questions_aggregate_fields'; count: number };
    };
    questions_approved: {
      __typename?: 'client_questionnaire_questions_aggregate';
      aggregate?: { __typename?: 'client_questionnaire_questions_aggregate_fields'; count: number };
    };
  }>;
};

export type DeleteClientQuestionnaireTableMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeleteClientQuestionnaireTableMutation = {
  __typename?: 'mutation_root';
  delete_client_questionnaires_by_pk?: { __typename?: 'client_questionnaires'; id: string };
};

export type UpdateClientQuestionnaireTableMutationVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid']['input'];
  input: Types.Client_Questionnaires_Set_Input;
}>;

export type UpdateClientQuestionnaireTableMutation = {
  __typename?: 'mutation_root';
  update_client_questionnaires_by_pk?: { __typename?: 'client_questionnaires'; id: string };
};

export type TableClientQuestionnaireFragment = {
  __typename?: 'client_questionnaires';
  id: string;
  internal_id?: string;
  name?: string;
  status: Types.Client_Questionnaire_Status_Enum;
  updated_at: string;
  company: string;
  due_date: string;
  owner_id?: string;
  questions_total: {
    __typename?: 'client_questionnaire_questions_aggregate';
    aggregate?: { __typename?: 'client_questionnaire_questions_aggregate_fields'; count: number };
  };
  questions_approved: {
    __typename?: 'client_questionnaire_questions_aggregate';
    aggregate?: { __typename?: 'client_questionnaire_questions_aggregate_fields'; count: number };
  };
};

export const TableClientQuestionnaireFragmentDoc = `
    fragment TableClientQuestionnaire on client_questionnaires {
  id
  internal_id
  name
  status
  updated_at
  company
  due_date
  owner_id
  questions_total: client_questionnaire_questions_aggregate {
    aggregate {
      count
    }
  }
  questions_approved: client_questionnaire_questions_aggregate(
    where: {status: {_eq: Approved}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetClientQuestionnairesTableDocument = `
    query GetClientQuestionnairesTable($organization_id: uuid!) {
  client_questionnaires(
    where: {organization_id: {_eq: $organization_id}}
    order_by: [{internal_id: asc}]
  ) {
    ...TableClientQuestionnaire
  }
}
    ${TableClientQuestionnaireFragmentDoc}` as string &
  TypedDocumentNode<GetClientQuestionnairesTableQuery, GetClientQuestionnairesTableQueryVariables>;
export const DeleteClientQuestionnaireTableDocument = `
    mutation DeleteClientQuestionnaireTable($id: uuid!) {
  delete_client_questionnaires_by_pk(id: $id) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    DeleteClientQuestionnaireTableMutation,
    DeleteClientQuestionnaireTableMutationVariables
  >;
export const UpdateClientQuestionnaireTableDocument = `
    mutation UpdateClientQuestionnaireTable($questionnaireId: uuid!, $input: client_questionnaires_set_input!) {
  update_client_questionnaires_by_pk(
    pk_columns: {id: $questionnaireId}
    _set: $input
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateClientQuestionnaireTableMutation,
    UpdateClientQuestionnaireTableMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetClientQuestionnairesTable: build.query<
      GetClientQuestionnairesTableQuery,
      GetClientQuestionnairesTableQueryVariables
    >({
      query: (variables) => ({ document: GetClientQuestionnairesTableDocument, variables }),
    }),
    DeleteClientQuestionnaireTable: build.mutation<
      DeleteClientQuestionnaireTableMutation,
      DeleteClientQuestionnaireTableMutationVariables
    >({
      query: (variables) => ({ document: DeleteClientQuestionnaireTableDocument, variables }),
    }),
    UpdateClientQuestionnaireTable: build.mutation<
      UpdateClientQuestionnaireTableMutation,
      UpdateClientQuestionnaireTableMutationVariables
    >({
      query: (variables) => ({ document: UpdateClientQuestionnaireTableDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetClientQuestionnairesTableQuery,
  useLazyGetClientQuestionnairesTableQuery,
  useDeleteClientQuestionnaireTableMutation,
  useUpdateClientQuestionnaireTableMutation,
} = injectedRtkApi;
