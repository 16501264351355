import { ThemeTypings } from '@chakra-ui/react';
import { ControlStatus } from '@main/graphql/client-scalars';
import { api as getRiskClassificationApi } from '@main/graphql/features/RiskClassifications.generated';
import { api as getRiskMatrixApi } from '@main/graphql/features/RiskMatrix.generated';
import { Treatment_Plan_Enum } from '@main/graphql/types.generated';
import { formatDate } from '@main/shared/utils';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import {
  constructMapKey,
  createMatrixMap,
  MatrixItem,
} from '../settings/organization/risks/matrix/utils';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getRiskApi, GetRisksQuery } from './get-risk.generated';

const getRiskApiData = createSelector(
  (state: AppRootState, riskId: string) => ({ state, riskId }),
  ({ state, riskId }) => {
    return getRiskApi.endpoints.GetRisk.select({
      risk_id: riskId,
    })(state).data?.risks_by_pk;
  },
);

export const getRiskTags = createSelector([getRiskApiData], (risk) => {
  if (!risk) {
    return undefined;
  }

  return risk.tags.map(({ id, tag }) => ({
    value: tag.id,
    label: tag.name,
    id,
    colorScheme: 'purple',
  }));
});

export const getRiskCategories = createSelector([getRiskApiData], (risk) => {
  if (!risk?.categories) {
    return undefined;
  }

  return risk.categories.map(({ id, category }) => ({
    value: category.id,
    label: category.name,
    id,
    colorScheme: 'purple',
  }));
});

const getRiskClassificationApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return getRiskClassificationApi.endpoints.GetRiskClassifications.select({
      organizationId: orgId,
    })(state).data;
  },
);

type Classification = {
  id: string;
  name: string;
};

export const getRiskClassificationMap = createSelector(
  [getRiskClassificationApiData],
  (classifications) => {
    if (!classifications) {
      return undefined;
    }

    const result = { impacts: {}, likelihoods: {} } as {
      impacts: Record<string, Classification>;
      likelihoods: Record<string, Classification>;
    };

    classifications.impacts.forEach((item) => {
      result.impacts[item.id] = { ...item };
    });

    classifications.likelihoods.forEach((item) => {
      result.likelihoods[item.id] = { ...item };
    });

    return result;
  },
);

export type Option = {
  value: string;
  label: string;
  id: string;
  colorScheme?: ThemeTypings['colorSchemes'];
};

const getRisksApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      getRiskApi.endpoints.GetRisks.select({
        organization_id: orgId,
      })(state).data?.risks || []
    );
  },
);

const getRiskMatrixDataApi = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    const data = getRiskMatrixApi.endpoints.GetRiskMatrix.select({
      organizationId: orgId,
    })(state).data;

    return {
      riskInherentLevels: data?.risk_inherent_levels || [],
      riskResidualLevels: data?.risk_residual_levels || [],
    };
  },
);

export const getMatrixLevelsMap = createSelector(
  [getRiskMatrixDataApi],
  ({ riskInherentLevels, riskResidualLevels }) => {
    const inherentLevelsMap = createMatrixMap(riskInherentLevels);
    const residualLevelsMap = createMatrixMap(riskResidualLevels);

    return { inherentLevelsMap, residualLevelsMap };
  },
);

type RiskClassifications = {
  inherent_impact?: {
    name: string;
  };
  inherent_likelihood?: {
    name: string;
  };
  residual_impact?: {
    name: string;
  };
  residual_likelihood?: {
    name: string;
  };
};

export const getInherentAndResidualLevelForRisk = (
  risk: RiskClassifications,
  riskMatrixLevels: ReturnType<typeof getMatrixLevelsMap>,
) => {
  return {
    inherentRiskLevel: riskMatrixLevels.inherentLevelsMap.get(
      constructMapKey({
        impactName: risk?.inherent_impact?.name ?? '',
        likelihoodName: risk?.inherent_likelihood?.name ?? '',
      }),
    )?.level,
    residualRiskLevel: riskMatrixLevels.residualLevelsMap.get(
      constructMapKey({
        impactName: risk?.residual_impact?.name ?? '',
        likelihoodName: risk?.residual_likelihood?.name ?? '',
      }),
    )?.level,
  };
};

const getRiskControlStatus = (risk: {
  risk_controls: { is_control_primary: boolean; control?: { status: ControlStatus } }[];
}) => {
  const uniqueRiskControlStatuses = new Set(
    risk.risk_controls
      .filter((riskControl) => riskControl.is_control_primary)
      .map(({ control }) => control?.status),
  );
  let riskControlStatus: ControlStatus | undefined;

  if (uniqueRiskControlStatuses.has(ControlStatus.FAILING)) {
    riskControlStatus = ControlStatus.FAILING;
  } else if (uniqueRiskControlStatuses.has(ControlStatus.AT_RISK)) {
    riskControlStatus = ControlStatus.AT_RISK;
  } else if (uniqueRiskControlStatuses.has(ControlStatus.PENDING)) {
    riskControlStatus = ControlStatus.PENDING;
  } else if (uniqueRiskControlStatuses.has(ControlStatus.VALID)) {
    riskControlStatus = ControlStatus.VALID;
  }

  return riskControlStatus;
};

const getIndex = (classification: string[], value: string | undefined) => {
  if (!value) {
    return;
  }
  return classification.indexOf(value) + 1;
};

const getRiskScores = (
  risk: RiskClassifications,
  classifications: ReturnType<typeof getRiskClassificationApiData>,
) => {
  const impacts = classifications?.impacts.map((impact) => impact.name) ?? [];
  const likelihoods = classifications?.likelihoods.map((likelihood) => likelihood.name) ?? [];

  const inherentImpactIndex = getIndex(impacts, risk.inherent_impact?.name);
  const inherentLikelihoodIndex = getIndex(likelihoods, risk.inherent_likelihood?.name);
  const residualImpactIndex = getIndex(impacts, risk.residual_impact?.name);
  const residualLikelihoodIndex = getIndex(likelihoods, risk.residual_likelihood?.name);

  let inherentScore = undefined;
  let residualScore = undefined;

  if (inherentImpactIndex && inherentLikelihoodIndex) {
    inherentScore = inherentImpactIndex * inherentLikelihoodIndex;
  }

  if (residualImpactIndex && residualLikelihoodIndex) {
    residualScore = residualImpactIndex * residualLikelihoodIndex;
  }

  return { inherentScore, residualScore };
};

export type OrganizationRisk = NonNullable<ReturnType<typeof getMappedRisks>>[number];

export const getMappedRisks = createSelector(
  [getRisksApiData, getMatrixLevelsMap, getRiskClassificationApiData],
  (risks, matrixLevelsMap, classifications) => {
    return risks.map((risk) => ({
      ...risk,
      controlStatus: getRiskControlStatus(risk),
      lastReviewedDate: risk.last_review_date,
      ...getInherentAndResidualLevelForRisk(risk, matrixLevelsMap),
      ...getRiskScores(risk, classifications),
    }));
  },
);

export const getMappedRisk = createSelector(
  [getRiskApiData, getMatrixLevelsMap, getRiskClassificationApiData],
  (risk, matrixLevelsMap, classifications) => {
    if (!risk) {
      return;
    }

    return {
      ...risk,
      ...getInherentAndResidualLevelForRisk(risk, matrixLevelsMap),
      ...getRiskScores(risk, classifications),
      lastReviewedDate: formatDate(risk.last_review_date),
    };
  },
);

export const getRiskStatsByStatus = createSelector([getRisksApiData], (risks) => {
  const riskMapByStatus = risks.reduce<Record<Treatment_Plan_Enum, number>>(
    (map, risk) => {
      map[risk.treatment_plan] = map[risk.treatment_plan] + 1;

      return map;
    },
    {
      Accepted: 0,
      Avoided: 0,
      Mitigated: 0,
      Transferred: 0,
      Pending: 0,
    },
  );

  return riskMapByStatus;
});

export const getRiskStatsByPassingControls = createSelector([getMappedRisks], (risks) => {
  let risksWithLinkedControls = 0;
  let risksWithPassingControls = 0;
  risks?.forEach((risk) => {
    if (risk.controlStatus) {
      risksWithLinkedControls += 1;
    }
    if (risk.controlStatus === ControlStatus.VALID) {
      risksWithPassingControls += 1;
    }
  });

  return { risksWithLinkedControls, risksWithPassingControls };
});

export type Risk = GetRisksQuery['risks'][number];
export const getRiskStatsByGroup = createSelector([getRisksApiData], (risks) => {
  const map = new Map<string, Risk[]>();
  risks.forEach((risk) => {
    risk.categories.forEach(({ category }) => {
      const key = category.name;
      const prevValue = map.get(key) || [];
      map.set(key, [...prevValue, risk]);
    });
  });
  return map;
});

export type MatrixItemWithRiskCount = MatrixItem & { counter?: number };

export const getRiskHeatmapData = createSelector(
  [getMatrixLevelsMap, getRisksApiData],
  ({ inherentLevelsMap, residualLevelsMap }, risks) => {
    const inherentLevelsHeatmap = new Map<string, MatrixItemWithRiskCount>(inherentLevelsMap);
    const residualLevelsHeatmap = new Map<string, MatrixItemWithRiskCount>(residualLevelsMap);

    risks.forEach((risk) => {
      if (risk.inherent_impact && risk.inherent_likelihood) {
        const inherentKey = constructMapKey({
          impactName: risk.inherent_impact.name,
          likelihoodName: risk.inherent_likelihood.name,
        });
        const prevValue = inherentLevelsHeatmap.get(inherentKey);
        prevValue &&
          inherentLevelsHeatmap.set(inherentKey, {
            ...prevValue,
            counter: (prevValue?.counter ?? 0) + 1,
          });
      }

      if (risk.residual_impact && risk.residual_likelihood) {
        const residualKey = constructMapKey({
          impactName: risk.residual_impact.name,
          likelihoodName: risk.residual_likelihood.name,
        });
        const prevValue = residualLevelsHeatmap.get(residualKey);
        prevValue &&
          residualLevelsHeatmap.set(residualKey, {
            ...prevValue,
            counter: (prevValue?.counter ?? 0) + 1,
          });
      }
    });

    return { inherentLevelsHeatmap, residualLevelsHeatmap };
  },
);
