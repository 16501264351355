import { Box, Heading, Stack } from '@chakra-ui/react';
import {
  ChatBubbleLeftRightIcon,
  ClockIcon,
  LinkIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';
import { Client_Questionnaire_Question_Status_Enum } from '@main/graphql/types.generated';
import { ClientQTabKeys, isNonNullable, useStableCallback } from '@main/shared/utils';
import {
  Drawer,
  DrawerActionsProps,
  getVisibleTabs,
  StatusTag,
  useStatefulCallback,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../hooks/use-org-guard';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { QuestionCommentsTab } from './question-comments-tab';
import { QuestionDetailsTab } from './question-details-tab';
import { useGetClientQquestionDrawerSubscription } from './question-drawer.subs';
import { QuestionHistoryTab } from './question-history-tab';
import { useClientQquestionStatusToOption } from './statuses';
import { useClientQquestionService } from './use-question-service';

export interface ClientQquestionDrawerProps {
  questionId: string;
}

export function ClientQquestionDrawer({ questionId }: ClientQquestionDrawerProps) {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewClientQ = userRole.permissionMap?.read_client_q;
  const drawerActions = useDrawerActions(questionId);
  const tabs = useDrawerTabs(questionId);
  const { data, isLoading } = useOrgGuard(
    useGetClientQquestionDrawerSubscription({ questionId }),
    ({ data }) => data?.question?.client_questionnaire.organization_id,
  );

  return (
    <Drawer.Layout isLoading={isLoading} canView={canViewClientQ} isNotFound={!data?.question}>
      <Drawer.Toolbar {...drawerActions} />
      <Drawer.Tabs tabs={tabs}>
        <DrawerHeader questionId={questionId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
}

function DrawerHeader({ questionId }: ClientQquestionDrawerProps) {
  const statusToOption = useClientQquestionStatusToOption();
  const { data } = useGetClientQquestionDrawerSubscription({ questionId });
  const status = useMemo(
    () => data?.question?.status && statusToOption(data?.question?.status),
    [data?.question?.status, statusToOption],
  );

  return (
    <Stack spacing="4" mb="2">
      <Box>
        <StatusTag colorScheme={status?.colorScheme}>{status?.label}</StatusTag>
      </Box>
      <Heading size="md">{data?.question?.question}</Heading>
    </Stack>
  );
}

function useDrawerActions(questionId: string): DrawerActionsProps {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditClientQ = userRole.permissionMap?.write_client_q;
  const { handleUpdate, updateStatus, approveAnswer, shareLink, isBusy } =
    useClientQquestionService();
  const { data } = useGetClientQquestionDrawerSubscription({ questionId });
  const questionStatus = data?.question?.status;

  const canApprove = useMemo(
    () =>
      !!canEditClientQ &&
      !!questionStatus &&
      ![
        Client_Questionnaire_Question_Status_Enum.Processing,
        Client_Questionnaire_Question_Status_Enum.Approved,
      ].includes(questionStatus),
    [canEditClientQ, questionStatus],
  );

  const canSetPending = useMemo(
    () =>
      !!canEditClientQ &&
      !!questionStatus &&
      ![
        Client_Questionnaire_Question_Status_Enum.Processing,
        Client_Questionnaire_Question_Status_Enum.Pending,
      ].includes(questionStatus),
    [canEditClientQ, questionStatus],
  );

  const canMarkForReview = useMemo(
    () =>
      !!canEditClientQ &&
      !!questionStatus &&
      ![
        Client_Questionnaire_Question_Status_Enum.Processing,
        Client_Questionnaire_Question_Status_Enum.ReadyForReview,
      ].includes(questionStatus),
    [canEditClientQ, questionStatus],
  );

  const setStatus = useStableCallback((status: Client_Questionnaire_Question_Status_Enum) =>
    handleUpdate(updateStatus({ questionId, status })),
  );

  const onApprove = useStatefulCallback(() =>
    approveAnswer(questionId, !!data?.question?.answers[0]?.answer),
  );

  const onSetPending = useStatefulCallback(() =>
    setStatus(Client_Questionnaire_Question_Status_Enum.Pending),
  );

  const onMarkForReview = useStatefulCallback(() =>
    setStatus(Client_Questionnaire_Question_Status_Enum.ReadyForReview),
  );

  return {
    primaryAction: useMemo(
      () =>
        canApprove
          ? {
              label: t('clientQquestionDrawer.actions.approve.label'),
              isDisabled: isBusy,
              isLoading: onApprove.isLoading,
              onClick: onApprove.callback,
            }
          : null,
      [canApprove, t, isBusy, onApprove],
    ),
    secondaryActions: useMemo(
      () =>
        [
          canSetPending
            ? ({
                variant: 'outline',
                label: t('clientQquestionDrawer.actions.setPending.label'),
                isDisabled: isBusy,
                isLoading: onSetPending.isLoading,
                onClick: onSetPending.callback,
              } as const)
            : null,
          canMarkForReview
            ? ({
                variant: 'outline',
                label: t('clientQquestionDrawer.actions.markForReview.label'),
                isDisabled: isBusy,
                isLoading: onMarkForReview.isLoading,
                onClick: onMarkForReview.callback,
              } as const)
            : null,
        ].filter(isNonNullable),
      [canSetPending, t, isBusy, onSetPending, canMarkForReview, onMarkForReview],
    ),
    menuActions: useMemo(
      () => [
        {
          icon: <LinkIcon />,
          label: t('clientQquestionDrawer.actions.share.label'),
          onClick: () => shareLink(questionId),
        },
      ],
      [questionId, shareLink, t],
    ),
  };
}

function useDrawerTabs(questionId: string) {
  const { t } = useTranslation();

  return useMemo(
    () =>
      getVisibleTabs<ClientQTabKeys>({
        details: {
          label: t('clientQquestionDrawer.tabs.details'),
          icon: ListBulletIcon,
          hideTabTitle: true,
          panel: <QuestionDetailsTab questionId={questionId} />,
        },
        comments: {
          label: t('clientQquestionDrawer.tabs.comments'),
          icon: ChatBubbleLeftRightIcon,
          panel: <QuestionCommentsTab questionId={questionId} />,
        },
        history: {
          label: t('clientQquestionDrawer.tabs.history'),
          icon: ClockIcon,
          panel: <QuestionHistoryTab questionId={questionId} />,
        },
      }),
    [questionId, t],
  );
}
