/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { FieldValueFragmentDoc } from '../../../../../libs/graphql/src/fragments/FieldValueFragments.generated';
import { CommentsFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/comments.fragment.generated';
export type TaskTableFragmentFragment = {
  __typename?: 'tasks';
  id: string;
  name?: string;
  status: ClientTypes.TaskStatus;
  due_date?: string;
  description?: string;
  created_by?: string;
  completed_at?: string;
  task_owners: Array<{ __typename?: 'task_owners'; completed_at?: string; owner_id: string }>;
  field_values: Array<{
    __typename?: 'field_values';
    field_config_id: string;
    entity_name: Types.Field_Entities_Enum;
    field_type: Types.Field_Types_Enum;
    text_field_value?: { __typename?: 'text_field_values'; field_value_id: string; value: string };
    url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
    date_field_value?: { __typename?: 'date_field_values'; field_value_id: string; value: string };
    number_field_value?: {
      __typename?: 'number_field_values';
      field_value_id: string;
      value: number;
    };
    email_field_value?: {
      __typename?: 'email_field_values';
      field_value_id: string;
      value: string;
    };
    select_field_value?: {
      __typename?: 'select_field_values';
      field_value_id: string;
      select_field_option_id: string;
    };
  }>;
};

export type TaskDrawerFragmentFragment = {
  __typename?: 'tasks';
  id: string;
  name?: string;
  status: ClientTypes.TaskStatus;
  due_date?: string;
  description?: string;
  internal_id?: string;
  completed_at?: string;
  created_at: string;
  created_by?: string;
  organization_id: string;
  task_owners: Array<{ __typename?: 'task_owners'; completed_at?: string; owner_id: string }>;
  tasks_comments: Array<{
    __typename?: 'comments';
    id: string;
    text: string;
    created_at: string;
    updated_at: string;
    user_id: string;
    comments_comment_uploads: Array<{
      __typename?: 'comment_uploads';
      id: string;
      comment_uploads_file: {
        __typename?: 'files';
        id: string;
        name?: string;
        size?: number;
        createdAt: string;
      };
    }>;
  }>;
  field_values: Array<{
    __typename?: 'field_values';
    field_config_id: string;
    entity_name: Types.Field_Entities_Enum;
    field_type: Types.Field_Types_Enum;
    text_field_value?: { __typename?: 'text_field_values'; field_value_id: string; value: string };
    url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
    date_field_value?: { __typename?: 'date_field_values'; field_value_id: string; value: string };
    number_field_value?: {
      __typename?: 'number_field_values';
      field_value_id: string;
      value: number;
    };
    email_field_value?: {
      __typename?: 'email_field_values';
      field_value_id: string;
      value: string;
    };
    select_field_value?: {
      __typename?: 'select_field_values';
      field_value_id: string;
      select_field_option_id: string;
    };
  }>;
};

export const TaskTableFragmentFragmentDoc = `
    fragment TaskTableFragment on tasks {
  id
  name
  status
  due_date
  description
  created_by
  completed_at
  task_owners {
    completed_at
    owner_id
  }
  field_values {
    ...FieldValue
  }
}
    `;
export const TaskDrawerFragmentFragmentDoc = `
    fragment TaskDrawerFragment on tasks {
  id
  name
  status
  due_date
  description
  internal_id
  completed_at
  task_owners {
    completed_at
    owner_id
  }
  created_at
  created_by
  organization_id
  tasks_comments(order_by: {created_at: asc}) {
    ...CommentsFragment
  }
  field_values {
    ...FieldValue
  }
}
    `;
