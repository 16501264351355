import { ZipWriter } from '@zip.js/zip.js';

import { BulkDownloaderLogger } from '../logger';
import { ServiceWorkerFileStreamer } from './file-streamer';

export class ZipperServiceWorkerFileStreamer implements ServiceWorkerFileStreamer {
  protected readonly fileName: string;
  protected readonly zipStream = new TransformStream();
  protected readonly zipWriter = new ZipWriter(this.zipStream.writable);

  constructor(
    fileName: string,
    protected readonly logger: BulkDownloaderLogger = console,
  ) {
    this.fileName = `${fileName}.zip`;
  }

  getFileName(): string {
    return this.fileName;
  }

  getStream(): ReadableStream {
    return this.zipStream.readable;
  }

  async streamFile(
    stream: ReadableStream,
    fileName: string,
    options?: { signal?: AbortSignal },
  ): Promise<void> {
    const filePath = fileName.replace(/[\\:*?"<>|]/g, '_');

    this.logger.debug('ZipperServiceWorkerFileStreamer Zipping file', {
      filePath,
      fileName: this.fileName,
    });

    await this.zipWriter.add(filePath, stream, options);

    this.logger.debug('ZipperServiceWorkerFileStreamer Zipped file', {
      filePath,
      fileName: this.fileName,
    });
  }

  async finalize(): Promise<void> {
    this.logger.debug('ZipperServiceWorkerFileStreamer Finalizing archive', {
      fileName: this.fileName,
    });

    await this.zipWriter.close();
  }
}
