/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AiAutocompleteEntityMutationVariables = Types.Exact<{
  input: Types.AiAutocompleteEntityInput;
}>;

export type AiAutocompleteEntityMutation = {
  __typename?: 'mutation_root';
  ai_autocomplete_entity?: {
    __typename?: 'AiAutocompleteEntityOutput';
    autocomplete: string;
    extra?: ClientTypes.AiAutocompleteEntityOutputExtra;
  };
};

export const AiAutocompleteEntityDocument = `
    mutation AiAutocompleteEntity($input: AiAutocompleteEntityInput!) {
  ai_autocomplete_entity(input: $input) {
    autocomplete
    extra
  }
}
    ` as string &
  TypedDocumentNode<AiAutocompleteEntityMutation, AiAutocompleteEntityMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AiAutocompleteEntity: build.mutation<
      AiAutocompleteEntityMutation,
      AiAutocompleteEntityMutationVariables
    >({
      query: (variables) => ({ document: AiAutocompleteEntityDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAiAutocompleteEntityMutation } = injectedRtkApi;
