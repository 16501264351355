import { Route, useParams } from '@tanstack/react-router';

import { ClientQuestionnaireQuestions } from '../../features/client-questionnaires/questionnaire-questions';
import { authLayout } from '../login';

export const clientQuestionnaireIdRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'client-questionnaires/$questionnaireId',
  component: () => (
    // eslint-disable-next-line react-hooks/rules-of-hooks
    <ClientQuestionnaireQuestions {...useParams({ from: clientQuestionnaireIdRoute.id })} />
  ),
});
