import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { Promisable } from '@main/shared/types';
import { useStableCallback } from '@main/shared/utils';
import { MouseEvent, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { animations } from '../../utils';

export interface AskAiButtonProps<T> extends ButtonProps {
  children?: ReactNode;
  loadingLabel?: ReactNode;
  recommendationLabel?: ReactNode;
  cancelLabel?: ReactNode;
  acceptLabel?: ReactNode;
  acceptButton?: (onAccept: (recommendation?: T) => void, recommendation?: T) => ReactNode;
  onRecommendationRequest: () => Promisable<T>;
  onRecommendationAccept: (recommendation: T) => void;
  onRecommendationCancel?: () => void;
  renderRecommendation?: (recommendation: T) => ReactNode;
}

export function AskAiButton<T>({
  children,
  loadingLabel,
  recommendationLabel,
  cancelLabel,
  acceptLabel,
  acceptButton,
  onRecommendationRequest,
  onRecommendationAccept,
  onRecommendationCancel,
  renderRecommendation,
  ...buttonProps
}: AskAiButtonProps<T>) {
  const { t } = useTranslation('ui');
  const [isLoading, setIsLoading] = useState(false);
  const [recommendation, setRecommendation] = useState<T>();

  const buttonColor = useColorModeValue('orange.400', 'orange.300');
  const buttonBorderColor = useColorModeValue('gray.200', 'gray.600');
  const recommendationColor = useColorModeValue('gray.600', 'gray.200');

  const icon = useMemo(() => <SparklesIcon width="12px" />, []);
  const loadingContent = useMemo(
    () => loadingLabel ?? <Box animation={`${animations.spin} infinite 2s linear`}>{icon}</Box>,
    [icon, loadingLabel],
  );

  async function onRequest(event: MouseEvent) {
    try {
      event.stopPropagation();
      setIsLoading(true);
      setRecommendation(undefined);
      setRecommendation(await onRecommendationRequest());
    } finally {
      setIsLoading(false);
    }
  }

  function onAccept(rec = recommendation) {
    if (!rec) {
      return;
    }

    onRecommendationAccept(rec);
    setRecommendation(undefined);
  }

  function onCancel() {
    if (!recommendation) {
      return;
    }

    onRecommendationCancel?.();
    setRecommendation(undefined);
  }

  const stableRenderRecommendation = useStableCallback(renderRecommendation);
  const render = useMemo(() => stableRenderRecommendation ?? String, [stableRenderRecommendation]);
  const recommendationElement = useMemo(
    () => (recommendation !== undefined ? render(recommendation) : null),
    [recommendation, render],
  );

  return (
    <Popover isOpen={!!recommendation} isLazy placement="top-end">
      <PopoverTrigger>
        {!recommendation ? (
          <Button
            variant="outline"
            size="xs"
            colorScheme="orange"
            fontWeight="medium"
            leftIcon={!isLoading ? icon : undefined}
            color={buttonColor}
            borderColor={buttonBorderColor}
            boxShadow="sm"
            onClick={onRequest}
            pointerEvents={isLoading ? 'none' : undefined}
            animation={isLoading ? `${animations.breath} infinite 1.5s linear` : undefined}
            {...buttonProps}
          >
            {isLoading ? loadingContent : children}
          </Button>
        ) : (
          <span></span>
        )}
      </PopoverTrigger>
      <PopoverContent w="md">
        <PopoverBody py="4" px="3">
          <HStack alignItems="start">
            <Box textColor={buttonColor} pt="2px">
              <SparklesIcon width="16px" />
            </Box>
            <Stack flexGrow="1" spacing="4">
              <Box fontSize="xs" color={recommendationColor}>
                <Text fontSize="sm" pb="2">
                  {recommendationLabel || t('ask-ai-button.recommendationLabel')}
                </Text>
                {recommendationElement}
              </Box>
              <Stack alignItems="flex-end">
                <ButtonGroup>
                  <Button variant="outline" size="xs" colorScheme="gray" onClick={onCancel}>
                    {cancelLabel || t('ask-ai-button.cancelLabel')}
                  </Button>
                  {acceptButton?.(onAccept, recommendation) ?? (
                    <Button variant="solid" size="xs" colorScheme="blue" onClick={() => onAccept()}>
                      {acceptLabel || t('ask-ai-button.acceptLabel')}
                    </Button>
                  )}
                </ButtonGroup>
              </Stack>
            </Stack>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
