import {
  BulkDownloaderLogger,
  DownloadFile,
  FileContent,
  IdentityServiceWorkerFileStreamer,
} from '@main/bulk-downloader';
import {
  DynamicFormFieldConfig,
  QuestionnaireFormComponentRegistry,
  resolveReadableValues,
  useQuestionnaireComponentRegistry,
} from '@main/dynamic-form';
import { maxTextLength, serializeCSV, sleep } from '@main/shared/utils';
import { useStableCallback } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { AppDownloadFile } from '../../bulk-downloader/app-stream-downloader';
import { AppFilesFetcher, useAppDownloader } from '../../bulk-downloader/use-app-downloader';
import {
  useLazyGetVqCombinedQuestionnaireForDownloadQuery,
  VqCombinedForDownloadFragment,
  VqCombinedQuestionnaireForDownloadFragment,
} from './vq-combined-downloader.generated';

export function useVQCombinedDownloader({ questionnaireId }: { questionnaireId: string }) {
  const { t } = useTranslation();

  const downloader = useAppDownloader({
    downloadId: `vq-combined-${questionnaireId}`,
    title: t('vendors.questionnaires.toasts.bulkDownload.title'),
    progressTitle: useStableCallback((progress: number) =>
      t('vendors.questionnaires.toasts.bulkDownload.progressTitle', {
        progress: progress.toFixed(1),
      }),
    ),
    successTitle: t('vendors.questionnaires.toasts.bulkDownload.successTitle'),
    errorTitle: t('vendors.questionnaires.toasts.bulkDownload.failTitle'),
  });
  const { componentRegistry } = useQuestionnaireComponentRegistry();

  const [fetchVQData, { isFetching }] = useLazyGetVqCombinedQuestionnaireForDownloadQuery();
  const isDownloading = isFetching || downloader.isDownloading;

  return {
    ...downloader,
    isDownloading,
    async start(name: string) {
      return downloader.start(
        `${maxTextLength(name)}_${Date.now()}`,
        new AppVQCombinedFilesFetcher(
          {
            downloadQuestionnaire: async (signal) => {
              signal?.throwIfAborted();

              const req = fetchVQData({ questionnaireId });
              const abortReq = req.abort.bind(req);
              signal?.addEventListener('abort', abortReq, { once: true });

              try {
                const { questionnaire } = await req.unwrap();

                if (!questionnaire) {
                  throw new Error('Questionnaire not found');
                }

                if (!questionnaire.form?.config) {
                  throw new Error('Cannot export a non-form combined questionnaire');
                }

                return questionnaire;
              } finally {
                signal?.removeEventListener('abort', abortReq);
              }
            },
          },
          componentRegistry,
          downloader.logger,
        ),
      );
    },
  };
}

export interface VQCombinedFetcher {
  downloadQuestionnaire(signal?: AbortSignal): Promise<VqCombinedQuestionnaireForDownloadFragment>;
}

export class AppVQCombinedFilesFetcher implements AppFilesFetcher {
  constructor(
    protected readonly vqFetcher: VQCombinedFetcher,
    protected readonly componentRegistry: QuestionnaireFormComponentRegistry,
    protected readonly logger: BulkDownloaderLogger = console,
  ) {}

  async fetchFiles(): Promise<DownloadFile<AppDownloadFile>[]> {
    return [{ name: '', file: new CombinedVQAsyncCSVFile(this.vqFetcher, this.componentRegistry) }];
  }

  getFileStreamer(fileName: string) {
    return new IdentityServiceWorkerFileStreamer(`${fileName}.csv`, this.logger);
  }
}

export class CombinedVQAsyncCSVFile implements FileContent {
  constructor(
    protected readonly vqFetcher: VQCombinedFetcher,
    protected readonly componentRegistry: QuestionnaireFormComponentRegistry,
  ) {}

  async content(signal?: AbortSignal) {
    signal?.throwIfAborted();

    const questionnaire = await this.vqFetcher.downloadQuestionnaire(signal);
    const questions = await this.collectQuestions(questionnaire, signal);

    return serializeCSV(
      ['Vendor', ...questions.map((q) => q.label ?? '')],
      await Promise.all(
        questionnaire.vendor_questionnaires.map(async (vq) => [
          vq.vendor.name ?? vq.vendor.internal_id ?? '',
          ...(await this.collectAnswers(vq, questions, signal)),
        ]),
      ),
    );
  }

  protected async collectQuestions(
    questionnaire: VqCombinedQuestionnaireForDownloadFragment,
    signal?: AbortSignal,
  ) {
    const questions = questionnaire.form?.config ?? [];
    const existingQuestions = Object.fromEntries(questions.map((c) => [c.name, true]));

    await Promise.all(
      questionnaire.vendor_questionnaires.map(async (vq) => {
        await this.pauseWork(signal);

        vq.form?.config_snapshot?.forEach((c) => {
          if (c.name in existingQuestions === false) {
            existingQuestions[c.name] = true;
            questions.push(c);
          }
        });
      }),
    );

    return questions;
  }

  protected async collectAnswers(
    vq: VqCombinedForDownloadFragment,
    questions: DynamicFormFieldConfig<QuestionnaireFormComponentRegistry>[],
    signal?: AbortSignal,
  ) {
    await this.pauseWork(signal);

    const answersMap = Object.fromEntries(vq.form?.answers.map((a) => [a.field_name, a]) ?? []);

    return questions.map((q) => {
      const answer = answersMap[q.name];

      if (!answer) {
        return '';
      }

      return resolveReadableValues(this.componentRegistry, { ...q, value: answer.value }, [
        answer.value,
      ]).join(', ');
    });
  }

  /** Allows main thread to process other tasks */
  protected async pauseWork(signal?: AbortSignal) {
    await sleep(16);
    signal?.throwIfAborted();
  }
}
