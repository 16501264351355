import { AppDrawerType } from '@main/shared/utils';
import { DrawerContentMap, useDrawerContent } from '@main/ui';
import { RouteApi } from '@tanstack/react-router';

import { ClientQquestionDrawer } from '../client-questionnaires/question-drawer';
import { ControlDrawer } from '../controls/drawer';
import { EvidenceDrawer } from '../evidence/drawer';
import { PolicyDrawer } from '../policies/drawer';
import { QuestionnaireDrawer } from '../questionnaires/drawer';
import { RiskDrawer } from '../risks/drawer';
import { TaskDrawer } from '../tasks/drawer';
import { VendorDrawer } from '../vendors/drawer';
import { VendorQuestionnaireDrawer } from '../vendors/vendor-questionnaires/drawer';

const drawers: DrawerContentMap<NonNullable<AppDrawerType['drawerEntity']>> = {
  control: ({ drawerEntityId }) => ({
    content: <ControlDrawer controlId={drawerEntityId} />,
  }),
  evidence: ({ drawerEntityId }) => ({
    content: <EvidenceDrawer evidenceId={drawerEntityId} />,
  }),
  task: ({ drawerEntityId }) => ({
    content: <TaskDrawer taskId={drawerEntityId} />,
  }),
  risk: ({ drawerEntityId }) => ({
    content: <RiskDrawer riskId={drawerEntityId} />,
  }),
  vendor: ({ drawerEntityId }) => ({
    content: <VendorDrawer vendorId={drawerEntityId} />,
  }),
  questionnaire: ({ drawerEntityId }) => ({
    content: <QuestionnaireDrawer questionnaireId={drawerEntityId} />,
  }),
  'vendor-questionnaire': ({ drawerEntityId }) => ({
    content: <VendorQuestionnaireDrawer vqId={drawerEntityId} />,
  }),
  policy: ({ drawerEntityId }) => ({
    content: <PolicyDrawer policyId={drawerEntityId} />,
  }),
  'policy-version': ({ drawerEntityId }) => {
    const [policyId, policyVersionId] = drawerEntityId.split(':');

    if (!policyId || !policyVersionId) {
      throw new Error('Invalid policy version id');
    }

    return {
      content: <PolicyDrawer policyId={policyId} />,
    };
  },
  'client-q-question': ({ drawerEntityId }) => ({
    content: <ClientQquestionDrawer questionId={drawerEntityId} />,
  }),
};

const rootRouteApi = new RouteApi({ id: '/' });

export function useAuthenticatedDrawer() {
  const { drawerContent } = useDrawerContent({
    useSearch: () =>
      rootRouteApi.useSearch({
        select: ({ drawerEntity, drawerEntityId }) => ({ drawerEntity, drawerEntityId }),
      }),
    drawers,
  });

  return drawerContent;
}
