import { ControlFindingGeneric } from '../control-finding';
import { FindingDescription, FindingHeading } from './shared';

export const ContentGeneric = ({ title, description }: ControlFindingGeneric) => {
  return (
    <>
      <FindingHeading>{title}</FindingHeading>
      {description && <FindingDescription>{description}</FindingDescription>}
    </>
  );
};
