/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { UseExportClientQuestionnaireFragmentDoc } from './use-export.generated';
import { ClientQuestionnaireQuestionTableFragmentDoc } from './questions-table.generated';
import { api } from '@main/graphql/shared-api';
export type GetClientQuestionnaireQuestionsQueryVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid']['input'];
}>;

export type GetClientQuestionnaireQuestionsQuery = {
  __typename?: 'query_root';
  clientQuestionnaire?: {
    __typename?: 'client_questionnaires';
    id: string;
    organization_id: string;
    status: Types.Client_Questionnaire_Status_Enum;
    name?: string;
    company: string;
    due_date: string;
    description?: string;
    export_file_id?: string;
    owner?: { __typename?: 'users'; id: string; displayName: string };
    questions: Array<{
      __typename?: 'client_questionnaire_questions';
      id: string;
      question: string;
      status: Types.Client_Questionnaire_Question_Status_Enum;
      metadata?: ClientTypes.ClientQquestionMetadata;
      answer: Array<{
        __typename?: 'client_questionnaire_answers';
        id: string;
        answer: string;
        comment: string;
        is_ai_generated: boolean;
      }>;
    }>;
  };
};

export type ExportClientQuestionnaireMutationVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid']['input'];
}>;

export type ExportClientQuestionnaireMutation = {
  __typename?: 'mutation_root';
  exportFile?: { __typename?: 'ExportClientQuestionnaireOutput'; file_id: string };
};

export type ClientQuestionnaireQuestionsFragment = {
  __typename?: 'client_questionnaires';
  id: string;
  organization_id: string;
  status: Types.Client_Questionnaire_Status_Enum;
  name?: string;
  company: string;
  due_date: string;
  description?: string;
  export_file_id?: string;
  owner?: { __typename?: 'users'; id: string; displayName: string };
  questions: Array<{
    __typename?: 'client_questionnaire_questions';
    id: string;
    question: string;
    status: Types.Client_Questionnaire_Question_Status_Enum;
    metadata?: ClientTypes.ClientQquestionMetadata;
    answer: Array<{
      __typename?: 'client_questionnaire_answers';
      id: string;
      answer: string;
      comment: string;
      is_ai_generated: boolean;
    }>;
  }>;
};

export const ClientQuestionnaireQuestionsFragmentDoc = `
    fragment ClientQuestionnaireQuestions on client_questionnaires {
  id
  organization_id
  status
  name
  company
  due_date
  description
  owner {
    id
    displayName
  }
  ...UseExportClientQuestionnaire
  questions: client_questionnaire_questions {
    ...ClientQuestionnaireQuestionTable
  }
}
    `;
export const GetClientQuestionnaireQuestionsDocument = `
    query GetClientQuestionnaireQuestions($questionnaireId: uuid!) {
  clientQuestionnaire: client_questionnaires_by_pk(id: $questionnaireId) {
    ...ClientQuestionnaireQuestions
  }
}
    ${ClientQuestionnaireQuestionsFragmentDoc}
${UseExportClientQuestionnaireFragmentDoc}
${ClientQuestionnaireQuestionTableFragmentDoc}` as string &
  TypedDocumentNode<
    GetClientQuestionnaireQuestionsQuery,
    GetClientQuestionnaireQuestionsQueryVariables
  >;
export const ExportClientQuestionnaireDocument = `
    mutation ExportClientQuestionnaire($questionnaireId: uuid!) {
  exportFile: export_client_questionnaire(
    input: {client_questionnaire_id: $questionnaireId}
  ) {
    file_id
  }
}
    ` as string &
  TypedDocumentNode<ExportClientQuestionnaireMutation, ExportClientQuestionnaireMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetClientQuestionnaireQuestions: build.query<
      GetClientQuestionnaireQuestionsQuery,
      GetClientQuestionnaireQuestionsQueryVariables
    >({
      query: (variables) => ({ document: GetClientQuestionnaireQuestionsDocument, variables }),
    }),
    ExportClientQuestionnaire: build.mutation<
      ExportClientQuestionnaireMutation,
      ExportClientQuestionnaireMutationVariables
    >({
      query: (variables) => ({ document: ExportClientQuestionnaireDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetClientQuestionnaireQuestionsQuery,
  useLazyGetClientQuestionnaireQuestionsQuery,
  useExportClientQuestionnaireMutation,
} = injectedRtkApi;
