import { Flex, Image } from '@chakra-ui/react';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { useAssetUrl } from '@main/shared/utils';

export const IntegrationLogo = ({ name }: { name: Integration_Names_Enum }) => {
  const assetUrl = useAssetUrl();
  const logoUrl = assetUrl(getLogoPath(name));

  return (
    <Flex align="center" shrink={0} w="72px" h="72px" p={4} borderRadius="base" bg="gray.50">
      <Image src={logoUrl} />
    </Flex>
  );
};

function getLogoPath(name: Integration_Names_Enum) {
  switch (name) {
    case Integration_Names_Enum.Onelogin:
      return `images/${name}-logo.png`;
    default:
      return `images/${name}-logo.svg`;
  }
}
