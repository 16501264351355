import { datadogLogs } from '@datadog/browser-logs';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast, successToast, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDuplicateQuestionnaireMutation } from './questionnaire.generated';

export function useDuplicateQuestionnaire() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const [_duplicateQuestionnaire] = useDuplicateQuestionnaireMutation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDuplicateQuestionnaires = userRole.permissionMap?.write_vendors;

  const duplicateQuestionnaire = useStableCallback(async (questionnaireId: string) => {
    try {
      const { duplicate_questionnaire } = await _duplicateQuestionnaire({
        questionnaireId,
      }).unwrap();
      if (duplicate_questionnaire?.id) {
        successToast(
          t('successMessages.duplicateSucceeded', { entity: t('entities.questionnaire') }),
        );
        drawer.open({ entity: 'questionnaire', entityId: duplicate_questionnaire.id });
      } else {
        errorToast(t('errorMessages.duplicateFailed', { entity: t('entities.questionnaire') }));
      }
    } catch (error) {
      errorToast(t('errorMessages.duplicateFailed', { entity: t('entities.questionnaire') }));
      datadogLogs.logger.error('Questionnaire duplicate failed', {}, toError(error));
    }
  });

  return canDuplicateQuestionnaires ? duplicateQuestionnaire : null;
}
