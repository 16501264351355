/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetClientQquestionDrawerQueryVariables = Types.Exact<{
  questionId: Types.Scalars['uuid']['input'];
}>;

export type GetClientQquestionDrawerQuery = {
  __typename?: 'query_root';
  question?: {
    __typename?: 'client_questionnaire_questions';
    id: string;
    status: Types.Client_Questionnaire_Question_Status_Enum;
    question: string;
    updated_at: string;
    client_questionnaire: {
      __typename?: 'client_questionnaires';
      id: string;
      organization_id: string;
    };
    answers: Array<{
      __typename?: 'client_questionnaire_answers';
      id: string;
      answer: string;
      comment: string;
      is_ai_generated: boolean;
    }>;
  };
};

export const GetClientQquestionDrawerDocument = `
    query GetClientQquestionDrawer($questionId: uuid!) {
  question: client_questionnaire_questions_by_pk(id: $questionId) {
    id
    status
    question
    updated_at
    client_questionnaire {
      id
      organization_id
    }
    answers: client_questionnaire_answers(order_by: {created_at: desc}, limit: 1) {
      id
      answer
      comment
      is_ai_generated
    }
  }
}
    ` as string &
  TypedDocumentNode<GetClientQquestionDrawerQuery, GetClientQquestionDrawerQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetClientQquestionDrawer: build.query<
      GetClientQquestionDrawerQuery,
      GetClientQquestionDrawerQueryVariables
    >({
      query: (variables) => ({ document: GetClientQquestionDrawerDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetClientQquestionDrawerQuery, useLazyGetClientQquestionDrawerQuery } =
  injectedRtkApi;
