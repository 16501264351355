import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ControlFindingPolicyError } from '../control-finding';
import { FindingDescription, FindingHeading, FullLogModal } from './shared';

export const ContentPolicyError = ({ cause, errorMessage }: ControlFindingPolicyError) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <FindingHeading>{t(`findings.cause.${cause}.title`)}</FindingHeading>
      <FindingDescription>{t(`findings.cause.${cause}.reason`)}</FindingDescription>
      <Flex justify="end">
        <Button variant="outline" onClick={onOpen}>
          {t('findings.fullLogModal.viewBtn')}
        </Button>
      </Flex>

      <FullLogModal isOpen={isOpen} onClose={onClose}>
        {errorMessage}
      </FullLogModal>
    </>
  );
};
