import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import { getCurrentUserId, getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getTasksApi, MyTasksQuery } from './my-tasks.generated';

const getTasksApiData = createSelector(
  [(state: AppRootState) => ({ state }), getCurrentUserId, getCurrentUserSelectedOrgId],
  ({ state }, userId, orgId) => {
    return getTasksApi.endpoints.MyTasks.select({
      userId,
      organizationId: orgId,
    })(state).data;
  },
);

type Task = MyTasksQuery['tasks'][number];
export const taskGroups = ['all', 'pending', 'completed'] as const;
export type TaskGroup = (typeof taskGroups)[number];

export const getGroupedTasks = createSelector([getTasksApiData], (data) => {
  const tasks = data?.tasks ?? [];

  const groupedTasks = tasks.reduce<{
    [key in TaskGroup]: Task[];
  }>(
    (acc, task) => {
      acc.all.push(task);
      if (task.completed_at) {
        acc.completed.push(task);
      } else {
        acc.pending.push(task);
      }
      return acc;
    },
    { all: [], pending: [], completed: [] },
  );

  return groupedTasks;
});
