/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateClientQquestionStatusMutationVariables = Types.Exact<{
  questionId: Types.Scalars['uuid']['input'];
  status: Types.Client_Questionnaire_Question_Status_Enum;
}>;

export type UpdateClientQquestionStatusMutation = {
  __typename?: 'mutation_root';
  update_client_questionnaire_questions_by_pk?: {
    __typename?: 'client_questionnaire_questions';
    id: string;
  };
};

export type UpdateClientQquestionAnswerMutationVariables = Types.Exact<{
  questionId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['uuid']['input'];
  answer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  comment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  isAIGenerated?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UpdateClientQquestionAnswerMutation = {
  __typename?: 'mutation_root';
  insert_client_questionnaire_answers_one?: {
    __typename?: 'client_questionnaire_answers';
    id: string;
  };
};

export const UpdateClientQquestionStatusDocument = `
    mutation UpdateClientQquestionStatus($questionId: uuid!, $status: client_questionnaire_question_status_enum!) {
  update_client_questionnaire_questions_by_pk(
    pk_columns: {id: $questionId}
    _set: {status: $status}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateClientQquestionStatusMutation,
    UpdateClientQquestionStatusMutationVariables
  >;
export const UpdateClientQquestionAnswerDocument = `
    mutation UpdateClientQquestionAnswer($questionId: uuid!, $userId: uuid!, $answer: String, $comment: String, $isAIGenerated: Boolean = false) {
  insert_client_questionnaire_answers_one(
    object: {client_questionnaire_question_id: $questionId, answer: $answer, comment: $comment, updated_by: $userId, is_ai_generated: $isAIGenerated}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateClientQquestionAnswerMutation,
    UpdateClientQquestionAnswerMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateClientQquestionStatus: build.mutation<
      UpdateClientQquestionStatusMutation,
      UpdateClientQquestionStatusMutationVariables
    >({
      query: (variables) => ({ document: UpdateClientQquestionStatusDocument, variables }),
    }),
    UpdateClientQquestionAnswer: build.mutation<
      UpdateClientQquestionAnswerMutation,
      UpdateClientQquestionAnswerMutationVariables
    >({
      query: (variables) => ({ document: UpdateClientQquestionAnswerDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateClientQquestionStatusMutation, useUpdateClientQquestionAnswerMutation } =
  injectedRtkApi;
