import { EvidenceIntegrationType, JumpcloudMdmCheckType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Jumpcloud]: HandlerConfigJumpcloud;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigJumpcloud {}
}

export const JumpcloudConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Jumpcloud> = {
  name: Integration_Names_Enum.Jumpcloud,

  orgConfig: z.object({
    apiKey: z.string().trim().min(1).describe('API Key'),
    organizationId: z.string().trim().optional().describe('Organization ID'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.MobileDeviceManagement),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(JumpcloudMdmCheckType.FullDiskEncryption),
            excludeSystems: z.string().array().optional(),
          }),
          z.object({
            type: z.literal(JumpcloudMdmCheckType.PatchPolicy),
            excludeSystems: z.string().array().optional(),
          }),
          z.object({
            type: z.literal(JumpcloudMdmCheckType.MfaEnrollment),
            excludeSystems: z.string().array().optional(),
            excludeUsers: z.string().array().optional(),
          }),
          z.object({
            type: z.literal(JumpcloudMdmCheckType.ActiveFirewall),
            excludeSystems: z.string().array().optional(),
          }),
        ]),
      ),
    }),
  ]),
};
