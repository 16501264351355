import { ParseKeys } from 'i18next';
import { Trans as ReactTrans, TransProps } from 'react-i18next';

/**
 * Default components for the {@link ReactTrans} component.
 * @see Trans
 */
export const TRANS_DEFAULT_COMPONENTS = {
  bold: <strong />,
};

/**
 * {@link ReactTrans} component with a default components {@link TRANS_DEFAULT_COMPONENTS}.
 */
export function Trans<Key extends ParseKeys>(props: TransProps<Key>) {
  return (
    <ReactTrans
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
      components={{ ...TRANS_DEFAULT_COMPONENTS, ...props.components }}
    />
  );
}
