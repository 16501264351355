import { isNonNullable } from '@main/shared/utils';

import {
  AnyDynamicFormComponentRegistry,
  DynamicFormFieldConfig,
  WithDynamicFormFieldConfigKind,
} from '../field-registry';
import { BaseDynamicFormFieldConfig } from '../types';

export function resolveReadableValues<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TConfig extends BaseDynamicFormFieldConfig &
    WithDynamicFormFieldConfigKind<string> = DynamicFormFieldConfig<TRegistry>,
>(
  registry: TRegistry,
  config: TConfig,
  defaultValues: (string | string[] | undefined)[],
): string[] {
  const component = registry.get(config.kind);
  const values = component.resolveReadableValues?.({ config }) ?? defaultValues;

  return values.flat().filter(isNonNullable);
}
