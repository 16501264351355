import { useModalContext } from '@chakra-ui/react';
import { useStableCallback } from '@main/shared/utils';
import { useEffect } from 'react';

import { useOptional } from './optional';

/**
 * Calls the provided callback when the modal is closed.
 * Must be used within a modal context or with a modal props parameter.
 */
export function useOnModalClosed(onClosed: () => void, modal?: { isOpen: boolean }) {
  const _onClosed = useStableCallback(onClosed);
  const modalCtx = useOptional(useModalContext);

  if (!modalCtx && !modal) {
    throw new Error(
      'useOnModalClosed must be used within a ModalContext or with a modal parameter!',
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isOpen = (modal?.isOpen ?? modalCtx?.isOpen)!;

  useEffect(() => {
    if (isOpen === false) {
      _onClosed();
    }
  }, [isOpen, _onClosed]);
}
