import { BulkDownloaderLogger } from '../logger';
import { ServiceWorkerFileStreamer } from './file-streamer';

export class IdentityServiceWorkerFileStreamer implements ServiceWorkerFileStreamer {
  protected readonly stream = new TransformStream();

  constructor(
    protected readonly fileName: string,
    protected readonly logger: BulkDownloaderLogger = console,
  ) {}

  getFileName(): string {
    return this.fileName;
  }

  getStream(): ReadableStream {
    return this.stream.readable;
  }

  async streamFile(
    stream: ReadableStream,
    filePath: string,
    options?: { signal?: AbortSignal },
  ): Promise<void> {
    this.logger.debug('IdentityServiceWorkerFileStreamer Streaming file...', {
      filePath,
      fileName: this.fileName,
    });

    await stream.pipeTo(this.stream.writable, { ...options, preventClose: true });

    this.logger.debug('IdentityServiceWorkerFileStreamer Streamed file', {
      filePath,
      fileName: this.fileName,
    });
  }

  async finalize(): Promise<void> {
    this.logger.debug('IdentityServiceWorkerFileStreamer Finalizing stream...', {
      fileName: this.fileName,
    });

    await this.stream.writable.close();
  }
}
