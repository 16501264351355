/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { ControlDetailsFragmentDoc } from '../../../../../libs/graphql/src/fragments/ControlDetailsFragment.generated';
import { FieldValueFragmentDoc } from '../../../../../libs/graphql/src/fragments/FieldValueFragments.generated';
import { CommentsFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/comments.fragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetControlQueryVariables = Types.Exact<{
  controlId: Types.Scalars['uuid']['input'];
}>;

export type GetControlQuery = {
  __typename?: 'query_root';
  controls_by_pk?: {
    __typename?: 'controls';
    created_at: string;
    id: string;
    name?: string;
    description?: string;
    status: ClientTypes.ControlStatus;
    internal_id?: string;
    assignee_id?: string;
    frequency: Types.Control_Frequencies_Enum;
    priority?: Types.Control_Priorities_Enum;
    irrelevant: boolean;
    organization_id: string;
    controls_comments: Array<{
      __typename?: 'comments';
      id: string;
      text: string;
      created_at: string;
      updated_at: string;
      user_id: string;
      comments_comment_uploads: Array<{
        __typename?: 'comment_uploads';
        id: string;
        comment_uploads_file: {
          __typename?: 'files';
          id: string;
          name?: string;
          size?: number;
          createdAt: string;
        };
      }>;
    }>;
    findings: Array<{
      __typename?: 'control_findings';
      id: string;
      type: Types.Finding_Types_Enum;
      cause: Types.Finding_Causes_Enum;
      ignored_at?: string;
      control_evidence_id?: string;
      task_id?: string;
      risk_id?: string;
      control_ai_review_assessment?: {
        __typename?: 'control_ai_review_assessments';
        criteria_heading: string;
        criteria_suggestion: string;
        assessment: string;
      };
      integration_run?: {
        __typename?: 'integration_runs';
        id: string;
        error_message?: string;
        check_failure_message?: string;
        evidence_integration: {
          __typename?: 'evidence_integrations';
          organization_integration: {
            __typename?: 'organization_integrations';
            integration: { __typename?: 'integrations'; name: Types.Integration_Names_Enum };
          };
        };
      };
      evidence_policy?: { __typename?: 'evidence_policies'; error_message?: string };
      manual_finding?: {
        __typename?: 'manual_findings';
        title: string;
        description: string;
        created_by?: string;
        created_at: string;
      };
    }>;
    control_evidences: Array<{
      __typename?: 'control_evidences';
      id: string;
      status: ClientTypes.ControlEvidenceStatus;
      evidence: {
        __typename?: 'evidences';
        id: string;
        internal_id?: string;
        name?: string;
        created_at: string;
        owner_id?: string;
        is_confidential: boolean;
        evidence_versions: Array<{
          __typename?: 'evidence_versions';
          id: string;
          validity_start: string;
          url?: string;
          evidence_version_file?: {
            __typename?: 'evidence_version_file';
            file: { __typename?: 'files'; id: string; name?: string };
          };
        }>;
        acl: Array<{ __typename?: 'evidence_acl'; id: string; user_id: string }>;
      };
    }>;
    programs: Array<{ __typename?: 'program_controls'; program_id: string }>;
    groups: Array<{
      __typename?: 'control_groups';
      group: { __typename?: 'groups'; id: string; name: string };
    }>;
    criterias: Array<{
      __typename?: 'control_criteria';
      criteria: { __typename?: 'criteria'; id: string; name: string };
    }>;
    custom_tags: Array<{
      __typename?: 'control_tags';
      tag: { __typename?: 'tags'; id: string; name: string };
    }>;
    categories: Array<{
      __typename?: 'control_categories';
      category: { __typename?: 'categories'; id: string; name: string };
    }>;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
      date_field_value?: {
        __typename?: 'date_field_values';
        field_value_id: string;
        value: string;
      };
      number_field_value?: {
        __typename?: 'number_field_values';
        field_value_id: string;
        value: number;
      };
      email_field_value?: {
        __typename?: 'email_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  };
};

export type DuplicateControlMutationVariables = Types.Exact<{
  controlId: Types.Scalars['String']['input'];
}>;

export type DuplicateControlMutation = {
  __typename?: 'mutation_root';
  duplicate_control?: { __typename?: 'CreateControlOutput'; id: string };
};

export type GetControlActivityHistoryQueryVariables = Types.Exact<{
  control_id: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetControlActivityHistoryQuery = {
  __typename?: 'query_root';
  audit_control_history_view: Array<{
    __typename?: 'audit_control_history_view';
    user_id?: string;
    action_timestamp?: string;
    action: ClientTypes.AuditTableActions;
    table_name: ClientTypes.ControlHistoryTables;
    hasura_user: ClientTypes.AuditTableHasuraUser;
    changed_fields?: ClientTypes.ControlChangedFields;
    row_data: ClientTypes.ControlRowData;
  }>;
};

export const GetControlDocument = `
    query GetControl($controlId: uuid!) {
  controls_by_pk(id: $controlId) {
    ...ControlDetails
    created_at
    controls_comments(order_by: {created_at: asc}) {
      ...CommentsFragment
    }
    findings(order_by: {created_at: asc, id: asc}) {
      id
      type
      cause
      ignored_at
      control_evidence_id
      task_id
      risk_id
      control_ai_review_assessment {
        criteria_heading
        criteria_suggestion
        assessment
      }
      integration_run {
        id
        error_message
        check_failure_message
        evidence_integration {
          organization_integration {
            integration {
              name
            }
          }
        }
      }
      evidence_policy {
        error_message
      }
      manual_finding {
        title
        description
        created_by
        created_at
      }
    }
    control_evidences(order_by: {evidence: {internal_id: asc}}) {
      id
      evidence {
        id
        internal_id
        name
        created_at
        owner_id
        is_confidential
        evidence_versions(where: {is_current: {_eq: true}}) {
          id
          validity_start
          url
          evidence_version_file {
            file {
              id
              name
            }
          }
        }
        acl(order_by: {created_at: asc}) {
          id
          user_id
        }
      }
      status
    }
  }
}
    ${ControlDetailsFragmentDoc}
${FieldValueFragmentDoc}
${CommentsFragmentFragmentDoc}` as string &
  TypedDocumentNode<GetControlQuery, GetControlQueryVariables>;
export const DuplicateControlDocument = `
    mutation DuplicateControl($controlId: String!) {
  duplicate_control(id: $controlId) {
    id
  }
}
    ` as string & TypedDocumentNode<DuplicateControlMutation, DuplicateControlMutationVariables>;
export const GetControlActivityHistoryDocument = `
    query GetControlActivityHistory($control_id: uuid!, $limit: Int = 10, $offset: Int = 0) {
  audit_control_history_view(
    limit: $limit
    offset: $offset
    where: {control_id: {_eq: $control_id}}
    order_by: {action_timestamp: desc}
  ) {
    user_id
    action_timestamp
    action
    table_name
    hasura_user
    changed_fields
    row_data
  }
}
    ` as string &
  TypedDocumentNode<GetControlActivityHistoryQuery, GetControlActivityHistoryQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetControl: build.query<GetControlQuery, GetControlQueryVariables>({
      query: (variables) => ({ document: GetControlDocument, variables }),
    }),
    DuplicateControl: build.mutation<DuplicateControlMutation, DuplicateControlMutationVariables>({
      query: (variables) => ({ document: DuplicateControlDocument, variables }),
    }),
    GetControlActivityHistory: build.query<
      GetControlActivityHistoryQuery,
      GetControlActivityHistoryQueryVariables
    >({
      query: (variables) => ({ document: GetControlActivityHistoryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetControlQuery,
  useLazyGetControlQuery,
  useDuplicateControlMutation,
  useGetControlActivityHistoryQuery,
  useLazyGetControlActivityHistoryQuery,
} = injectedRtkApi;
