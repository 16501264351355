import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { TrashIcon } from '@heroicons/react/24/outline';
import { maxTextLength, toError, useStableCallback } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  Trans,
  useAlertDialog,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { dateToMidnightUTC } from '../../utils/date';
import { useTableSettings } from '../../utils/table-settings';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useClientQstatusToOption } from './statuses';
import {
  TableClientQuestionnaireFragment,
  useDeleteClientQuestionnaireTableMutation,
  useUpdateClientQuestionnaireTableMutation,
} from './table.generated';
import { useGetClientQuestionnairesTableSubscription } from './table.subs';

export function ClientQuestionnairesTable() {
  const { t } = useTranslation();
  const organization_id = useAppSelector(getCurrentUserSelectedOrgId);
  const { data, isLoading } = useGetClientQuestionnairesTableSubscription({ organization_id });

  const columns = useClientQuestionnaireColumns();
  const { columnVisibility, setColumnVisibility } = useTableSettings({
    storageKey: 'client-questionnaires:table:column-visibility',
  });
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'client-questionnaires:table:filter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({
    searchParam: 'client-questionnaires:table:sort',
  });

  const tableItemName = useMemo(
    () => ({
      singular: t('entities.questionnaire').toLowerCase(),
      plural: t('entities.plural.questionnaire').toLowerCase(),
    }),
    [t],
  );

  return (
    <Table
      minW="900px"
      columns={columns}
      data={data?.client_questionnaires || []}
      isLoading={isLoading}
      itemName={tableItemName}
      pageSize={15}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
}

function useClientQuestionnaireColumns() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openDialog } = useAlertDialog();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const organization_id = useAppSelector(getCurrentUserSelectedOrgId);
  const { refetch: refetchClientQuestionnaires } = useGetClientQuestionnairesTableSubscription({
    organization_id,
  });
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditClientQ = !!userRole.permissionMap?.write_client_q;
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const orgUsersOptions = useMemo(
    () =>
      currentOrgNonDisabledUsers.map((user) => ({
        id: user.id,
        displayName: user.displayName,
      })),
    [currentOrgNonDisabledUsers],
  );
  const statusToOption = useClientQstatusToOption();
  const [deleteClientQuestionnaire] = useDeleteClientQuestionnaireTableMutation();
  const [updateClientQuestionnaire] = useUpdateClientQuestionnaireTableMutation();

  const deleteQuestionnaireHandler = useStableCallback(async (id: string) => {
    try {
      await deleteClientQuestionnaire({ id }).unwrap();
      successToast(
        t('successMessages.deleteSucceeded', { entity: t('entities.clientQuestionnaire') }),
      );
    } catch (error) {
      datadogLogs.logger.error('Failed to delete client questionnaire', { id }, toError(error));
      errorToast(t('errorMessages.deleteFailed', { entity: t('entities.clientQuestionnaire') }));
    }
  });

  const handleQuestionnaireUpdate = useStableCallback(
    async (update: ReturnType<typeof updateClientQuestionnaire>) => {
      try {
        await update.unwrap();
        refetchClientQuestionnaires();
        successToast(t('clientQuestionnaires.updateSuccessful'));
      } catch (error) {
        datadogLogs.logger.error('Failed to update Client Questionnaire', {}, toError(error));
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.clientQuestionnaire') }));
      }
    },
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<TableClientQuestionnaireFragment>();

    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('clientQuestionnaires.tableColumns.status'),
        accessorFn: ({ status }) => statusToOption(status),
        enableSorting: true,
        size: 120,
      }),
      columnHelper.columns.text({
        id: 'name',
        header: t('clientQuestionnaires.tableColumns.name'),
        accessorFn: ({ name }) => name,
        enableGlobalFilter: true,
        enableSorting: true,
        meta: {
          cell: {
            onClick: (cell) =>
              navigate({
                to: `/client-questionnaires/$questionnaireId`,
                params: { questionnaireId: cell.row.original.id },
              }),
          },
        },
        edit: {
          onChange: (row, name) =>
            handleQuestionnaireUpdate(
              updateClientQuestionnaire({
                questionnaireId: row.original.id,
                input: { name },
              }),
            ),
          canEditGuard: () => canEditClientQ,
        },
      }),
      columnHelper.columns.text({
        id: 'company',
        header: t('clientQuestionnaires.tableColumns.company'),
        accessorFn: ({ company }) => company,
        enableGlobalFilter: true,
        enableSorting: true,
        size: 150,
        edit: {
          onChange: (row, company) =>
            handleQuestionnaireUpdate(
              updateClientQuestionnaire({
                questionnaireId: row.original.id,
                input: { company },
              }),
            ),
          canEditGuard: () => canEditClientQ,
        },
      }),
      columnHelper.columns.date({
        id: 'dueDate',
        header: t('clientQuestionnaires.tableColumns.dueDate'),
        accessorFn: ({ due_date }) => due_date,
        enableSorting: true,
        size: 160,
        edit: {
          onChange: (row, dueDate) =>
            handleQuestionnaireUpdate(
              updateClientQuestionnaire({
                questionnaireId: row.original.id,
                input: { due_date: dateToMidnightUTC(dueDate) },
              }),
            ),
          canEditGuard: () => canEditClientQ,
        },
      }),
      columnHelper.columns.text({
        id: 'progress',
        header: t('clientQuestionnaires.tableColumns.progress'),
        accessorFn: ({ questions_approved, questions_total }) =>
          `${questions_approved.aggregate?.count ?? 0}/${questions_total.aggregate?.count ?? 0}`,
        size: 90,
      }),
      columnHelper.columns.avatar({
        id: 'owner',
        header: t('clientQuestionnaires.tableColumns.owner'),
        accessorFn: ({ owner_id }) => {
          if (!owner_id) {
            return;
          }

          const user = currentOrgUsers[owner_id];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 70,
        enableColumnFilter: true,
        enableSorting: true,
        edit: canEditClientQ
          ? {
              options: orgUsersOptions,
              onChange: (row, user) =>
                handleQuestionnaireUpdate(
                  updateClientQuestionnaire({
                    questionnaireId: row.original.id,
                    input: { owner_id: user?.id ?? null },
                  }),
                ),
            }
          : undefined,
      }),
      columnHelper.columns.date({
        id: 'updatedAt',
        header: t('clientQuestionnaires.tableColumns.updatedAt'),
        accessorFn: ({ updated_at }) => updated_at,
        enableSorting: true,
        size: 160,
      }),
      ...(canEditClientQ
        ? [
            columnHelper.columns.actions({
              size: 50,
              PrimaryAction: (context) => (
                <Tooltip label={t('buttons.delete')} placement="top">
                  <IconButton
                    minW={4}
                    variant="link"
                    aria-label={t('buttons.delete')}
                    icon={<Icon as={TrashIcon} />}
                    onClick={() =>
                      openDialog({
                        dialogHeader: t('clientQuestionnaires.alert.delete.header'),
                        dialogContent: (
                          <Trans
                            i18nKey="clientQuestionnaires.alert.delete.content"
                            values={{
                              name: maxTextLength(
                                `${context.row.original.internal_id} - ${context.row.original.name}`,
                              ),
                            }}
                          />
                        ),
                        confirmAction: {
                          children: t('buttons.delete'),
                          onClick: () => deleteQuestionnaireHandler(context.row.original.id),
                        },
                      })
                    }
                  />
                </Tooltip>
              ),
            }),
          ]
        : []),
    ];
  }, [
    canEditClientQ,
    currentOrgUsers,
    deleteQuestionnaireHandler,
    handleQuestionnaireUpdate,
    navigate,
    openDialog,
    orgUsersOptions,
    statusToOption,
    t,
    updateClientQuestionnaire,
  ]);
}
