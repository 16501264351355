import { BulkDownloaderLogger } from '../logger';
import { StreamPortalFactory } from '../stream-portal';
import { ServiceWorkerFileStreamer } from './file-streamer';
import { ZipperServiceWorkerFileStreamer } from './file-streamer-zipper';
import { ServiceWorkerStreamSink } from './stream-sink';

export interface CreateSWStreamSinkOptions {
  fileName: string;
  url: string;
  type: WorkerType;
  scope: string;
  streamer?: ServiceWorkerFileStreamer;
  logger?: BulkDownloaderLogger;
}

export async function createSWStreamSink(options: CreateSWStreamSinkOptions) {
  options.logger?.debug('Creating SW downloader', {
    url: options.url,
    scope: options.scope,
    type: options.type,
  });

  const swReg =
    (await navigator.serviceWorker.getRegistration(options.scope)) ??
    (await navigator.serviceWorker.register(options.url, {
      type: options.type,
      scope: options.scope,
    }));
  let sw = swReg.active;

  if (!sw) {
    const swRegTmp = swReg.installing ?? swReg.waiting;

    if (!swRegTmp) {
      throw new Error('Unable to get service worker registration');
    }

    sw = await new Promise<ServiceWorker>((res) => {
      swRegTmp.addEventListener('statechange', () => {
        if (swRegTmp.state === 'activated') {
          res(swRegTmp);
        }
      });
    });
  }

  return new ServiceWorkerStreamSink(
    sw,
    StreamPortalFactory.createSource(sw, options.logger),
    options.streamer ?? new ZipperServiceWorkerFileStreamer(options.fileName, options.logger),
    options.logger,
  );
}
