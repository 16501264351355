import {
  AwsCloudwatchCheckType,
  AwsGuarddutyCheckType,
  AwsIamCheckTypes,
  AwsInspectorCheck,
  AwsObjectStorageCheck,
  DBIntegrationCheck,
  EvidenceIntegrationType,
  GuardDutyFeature,
  LogMetricFilterCheck,
} from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Aws]: HandlerConfigAws;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigAws {}
}

export const AwsConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Aws> = {
  name: Integration_Names_Enum.Aws,

  orgConfig: z.object({
    accessKeyId: z.string().min(1).describe('Access Key ID'),
    secretAccessKey: z.string().min(1).describe('Secret Access Key'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.Database),
      region: z.string(),
      dbInstanceIds: z.array(z.string()).optional(),
      dbClusterIds: z.array(z.string()).optional(),
      checks: z.array(z.nativeEnum(DBIntegrationCheck)),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.ObjectStorage),
      region: z.string(),
      buckets: z.array(z.string()),
      check: z.nativeEnum(AwsObjectStorageCheck),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Guardduty),
      region: z.string(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.enum([
              AwsGuarddutyCheckType.HighSeverityFindings,
              AwsGuarddutyCheckType.SnsSubscription,
            ]),
          }),
          z.object({
            type: z.literal(AwsGuarddutyCheckType.EnabledFeatures),
            features: z.array(z.nativeEnum(GuardDutyFeature)),
          }),
        ]),
      ),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Inspector),
      region: z.string(),
      checks: z.array(z.nativeEnum(AwsInspectorCheck)),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Cloudwatch),
      region: z.string(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(AwsCloudwatchCheckType.AlarmActionsConfigured),
            insufficientDataActionRequired: z.boolean().optional(),
            okActionRequired: z.boolean().optional(),
          }),
          z.object({
            type: z.enum([AwsCloudwatchCheckType.AlarmActionsActivated]),
          }),
          z.object({
            type: z.literal(AwsCloudwatchCheckType.LogGroupsRetention),
            retentionTimeInDays: z.number(),
            logGroupPrefixes: z.array(z.string()).optional(),
          }),
          z.object({
            type: z.literal(AwsCloudwatchCheckType.LogMetricFilter),
            checks: z.array(z.nativeEnum(LogMetricFilterCheck)).nonempty(),
          }),
        ]),
      ),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.IdentityAccessManagement),
      region: z.string(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(AwsIamCheckTypes.AccessKeyRotation),
            rotationDays: z.number(),
          }),
          z.object({
            type: z.literal(AwsIamCheckTypes.PasswordRotation),
            rotationDays: z.number(),
          }),
          z.object({
            type: z.literal(AwsIamCheckTypes.UnusedCredentials),
            inactiveDays: z.number(),
          }),
          z.object({
            type: z.enum([
              AwsIamCheckTypes.NoAdminPrivileges,
              AwsIamCheckTypes.NoWildcardActionsForServices,
              AwsIamCheckTypes.NoPoliciesAttached,
              AwsIamCheckTypes.MfaEnabledAllUsers,
              AwsIamCheckTypes.MfaEnabledConsoleAccess,
              AwsIamCheckTypes.NoCloudShellFullAccess,
              AwsIamCheckTypes.ExternalAccessAnalyzer,
              AwsIamCheckTypes.PasswordPolicyConfiguration,
              AwsIamCheckTypes.ExpiredServerCertificate,
              AwsIamCheckTypes.SupportRole,
            ]),
          }),
        ]),
      ),
    }),
  ]),
};
