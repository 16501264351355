import {
  Button,
  FormLabel,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Client_Questionnaire_Question_Status_Enum } from '@main/graphql/types.generated';
import { AutoResizeTextarea, DrawerProperty, HoverAskAiButton } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import {
  getCurrentUserId,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useGetClientQquestionDrawerSubscription } from './question-drawer.subs';
import { useClientQquestionService } from './use-question-service';

export function QuestionDetailsTab({ questionId }: { questionId: string }) {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  const { is_day_zero_ai_features_enabled } = useAppSelector(getCurrentUserSelectedOrg);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditClientQ = userRole.permissionMap?.write_client_q;
  const { data } = useGetClientQquestionDrawerSubscription({ questionId });
  const question = data?.question;
  const answer = question?.answers[0];
  const { handleUpdate, updateStatus, updateAnswer, autocompleteAnswer, acceptAutocompleteAnswer } =
    useClientQquestionService();

  async function handleAnswerChange(value = answer?.answer, comment = answer?.comment) {
    if (value === undefined && comment === undefined) {
      return;
    }

    if (value === answer?.answer && comment === answer?.comment) {
      return;
    }

    if (!answer?.answer && !value && !answer?.comment && !comment) {
      return;
    }

    if (question?.status === Client_Questionnaire_Question_Status_Enum.Processing) {
      await handleUpdate(
        updateStatus({
          questionId,
          status: Client_Questionnaire_Question_Status_Enum.Pending,
        }).unwrap(),
        false,
      );
    }

    await handleUpdate(
      updateAnswer({
        questionId,
        userId,
        answer: value ?? '',
        comment: comment ?? '',
      }).unwrap(),
    );
  }

  if (!question) {
    return null;
  }

  return (
    <Stack spacing={6}>
      <DrawerProperty isReadOnly={!canEditClientQ} flexDirection="column">
        <FormLabel fontSize="xs" fontWeight="semibold">
          {t('clientQquestionDrawer.answer.label')}
        </FormLabel>
        <DrawerProperty.Content {...HoverAskAiButton.ParentProps}>
          <AutoResizeTextarea
            key={answer?.answer}
            defaultValue={answer?.answer}
            placeholder={t('clientQquestionDrawer.answer.placeholder')}
            onBlur={(event) => handleAnswerChange(event.target.value)}
          />
          {is_day_zero_ai_features_enabled && canEditClientQ && (
            <HoverAskAiButton
              data-testid="autocomplete-clientq-question-answer"
              renderRecommendation={(recommendation) => (
                <VStack alignItems="flex-start">
                  <VStack alignItems="flex-start" spacing="1">
                    <Text fontSize="xs" fontWeight="semibold">
                      {t('clientQquestionDrawer.answer.label')}
                    </Text>
                    <Text data-testid="clientq-question-answer" fontSize="xs">
                      {recommendation?.answer}
                    </Text>
                  </VStack>
                  <VStack alignItems="flex-start" spacing="1">
                    <Text fontSize="xs" fontWeight="semibold">
                      {t('clientQquestionDrawer.comment.label')}
                    </Text>
                    <Text data-testid="clientq-question-answer-comment" fontSize="xs">
                      {recommendation?.comment}
                    </Text>
                  </VStack>
                </VStack>
              )}
              acceptButton={(onAccept, recommendation) => (
                <Menu>
                  <MenuButton
                    as={Button}
                    size="xs"
                    variant="solid"
                    colorScheme="blue"
                    rightIcon={<Icon as={ChevronDownIcon} />}
                  >
                    {t(
                      answer?.answer
                        ? 'clientQquestionDrawer.askAi.replaceLabel'
                        : 'clientQquestionDrawer.askAi.acceptLabel',
                    )}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => onAccept()}>
                      {t('clientQquestionDrawer.askAi.allLabel')}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onAccept({
                          answer: recommendation?.answer ?? '',
                          comment: answer?.comment ?? '',
                        })
                      }
                    >
                      {t('clientQquestionDrawer.askAi.answerLabel')}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onAccept({
                          comment: recommendation?.comment ?? '',
                          answer: answer?.answer ?? '',
                        })
                      }
                    >
                      {t('clientQquestionDrawer.askAi.commentLabel')}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
              onRecommendationRequest={() => autocompleteAnswer(questionId)}
              onRecommendationAccept={(recommendation) =>
                recommendation && acceptAutocompleteAnswer(questionId, userId, recommendation)
              }
            >
              {answer?.answer ? t('askAiButton.regenerateLabel') : t('askAiButton.generateLabel')}
            </HoverAskAiButton>
          )}
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditClientQ} flexDirection="column">
        <FormLabel fontSize="xs" fontWeight="semibold">
          {t('clientQquestionDrawer.comment.label')}
        </FormLabel>
        <DrawerProperty.Content>
          <AutoResizeTextarea
            key={answer?.comment}
            defaultValue={answer?.comment}
            placeholder={t('clientQquestionDrawer.comment.placeholder')}
            minRows={3}
            onBlur={(event) => handleAnswerChange(undefined, event.target.value)}
          />
        </DrawerProperty.Content>
      </DrawerProperty>
    </Stack>
  );
}
