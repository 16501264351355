import { api as getRolePermissionsApi } from '@main/graphql/queries/GetRolePermissions.generated';
import { Permissions_Enum } from '@main/graphql/types.generated';
import {
  getGroupedPermissions,
  groupOrder,
  permissionToGroupOrder,
  TranslatedPermission,
} from '@main/permissions';
import { createSelector } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';

import { AppRootState } from '../../../../store';
import { getTFunction } from '../../../../utils/i18n';
import { FeatureFlagsState, getFeatureFlags } from '../../../feature-flags/slice';
import { getCurrentUserSelectedOrg, Organization } from '../../../user/slice';

interface PermissionsState {
  featureFlags: FeatureFlagsState;
  userOrg: Organization;
}

const permissionsMask: Partial<
  Record<Permissions_Enum, (state: PermissionsState, permission: TranslatedPermission) => boolean>
> = {
  // Controls
  write_programs: ({ userOrg }) => userOrg.is_controls_module_enabled,
  read_controls: ({ userOrg }) => userOrg.is_controls_module_enabled,
  update_controls: ({ userOrg }) => userOrg.is_controls_module_enabled,
  create_controls: ({ userOrg }) => userOrg.is_controls_module_enabled,
  link_controls_evidence: ({ userOrg }) => userOrg.is_controls_module_enabled,
  link_controls_programs: ({ userOrg }) => userOrg.is_controls_module_enabled,
  link_controls_risks: ({ userOrg }) => userOrg.is_controls_module_enabled,
  notify_on_control_at_risk: ({ userOrg }) => userOrg.is_controls_module_enabled,
  write_control_field_configs: ({ userOrg }) => userOrg.is_controls_module_enabled,
  // Evidence
  read_evidence: ({ userOrg }) => userOrg.is_controls_module_enabled,
  write_evidence: ({ userOrg }) => userOrg.is_controls_module_enabled,
  read_confidential_evidence: ({ userOrg }) => userOrg.is_controls_module_enabled,
  write_confidential_evidence: ({ userOrg }) => userOrg.is_controls_module_enabled,
  // Risks
  read_risks: ({ userOrg }) => userOrg.is_risks_module_enabled,
  write_risks: ({ userOrg }) => userOrg.is_risks_module_enabled,
  manage_risk_classification: ({ userOrg }) => userOrg.is_risks_module_enabled,
  write_risk_field_configs: ({ userOrg }) => userOrg.is_risks_module_enabled,
  notify_on_risk_expiring: ({ userOrg }) => userOrg.is_risks_module_enabled,
  // Vendors
  read_vendors: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  write_vendors: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  write_vendor_field_configs: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  notify_on_vendor_expiring: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  link_risks_vendors: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  notify_on_questionnaire_overdue: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  // Policies
  read_policies: ({ userOrg }) => userOrg.is_policies_module_enabled,
  write_policies: ({ userOrg }) => userOrg.is_policies_module_enabled,
  write_policy_field_configs: ({ userOrg }) => userOrg.is_policies_module_enabled,
  acknowledge_policies: ({ userOrg }) => userOrg.is_policies_module_enabled,
  // Client Questionnaires
  read_client_q: ({ featureFlags }) => !!featureFlags['client-questionnaires'],
  write_client_q: ({ featureFlags }) => !!featureFlags['client-questionnaires'],
};

const getPermissionsState = createSelector(
  [getFeatureFlags, getCurrentUserSelectedOrg],
  (featureFlags, userOrg) => ({ featureFlags, userOrg }) satisfies PermissionsState,
);

const getRolePermissionsApiData = createSelector(
  (state: AppRootState, t: TFunction, roleId: string) => ({ state, roleId }),
  ({ state, roleId }) => {
    return (
      getRolePermissionsApi.endpoints.GetRolePermissions.select({
        roleId,
      })(state).data?.role_permissions || []
    );
  },
);

export const getMappedRolePermissions = createSelector(
  [getRolePermissionsApiData, getTFunction],
  (rolePermissions, t) => {
    const groupedPermissions = getGroupedPermissions(t);
    rolePermissions.forEach(({ permission, id }) => {
      groupedPermissions[permission].isActive = true;
      groupedPermissions[permission].id = id;
    });

    // convert to array and sort
    const permissionsArray = Object.values(groupedPermissions);

    permissionsArray.sort((a, b) => {
      const groupAOrder = groupOrder.indexOf(permissionToGroupOrder[a.name].group);
      const groupBOrder = groupOrder.indexOf(permissionToGroupOrder[b.name].group);
      if (groupAOrder === groupBOrder) {
        // same group, sort by internal order
        return permissionToGroupOrder[a.name].order - permissionToGroupOrder[b.name].order;
      }
      return groupAOrder - groupBOrder;
    });

    return permissionsArray.filter((permission) => !permission.hidden);
  },
);

export const getPermissions = createSelector(
  [getMappedRolePermissions, getPermissionsState],
  (permissions, state) => permissions.filter((p) => permissionsMask[p.name]?.(state, p) ?? true),
);
