/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type DeleteTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
}>;

export type DeleteTaskMutation = {
  __typename?: 'mutation_root';
  delete_tasks_by_pk?: { __typename?: 'tasks'; id: string };
};

export type UpdateTaskByIdMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  updatePayload: Types.Tasks_Set_Input;
}>;

export type UpdateTaskByIdMutation = {
  __typename?: 'mutation_root';
  update_tasks_by_pk?: { __typename?: 'tasks'; id: string };
};

export type CreateTaskMutationVariables = Types.Exact<{
  input: Types.Tasks_Insert_Input;
}>;

export type CreateTaskMutation = {
  __typename?: 'mutation_root';
  insert_tasks_one?: { __typename?: 'tasks'; id: string };
};

export type CreateTaskOwnerMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  ownerId: Types.Scalars['uuid']['input'];
}>;

export type CreateTaskOwnerMutation = {
  __typename?: 'mutation_root';
  delete_task_owners?: {
    __typename?: 'task_owners_mutation_response';
    returning: Array<{ __typename?: 'task_owners'; owner_id: string }>;
  };
  insert_task_owners_one?: { __typename?: 'task_owners'; owner_id: string };
};

export type RemoveTaskOwnerMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
}>;

export type RemoveTaskOwnerMutation = {
  __typename?: 'mutation_root';
  delete_task_owners?: {
    __typename?: 'task_owners_mutation_response';
    returning: Array<{ __typename?: 'task_owners'; owner_id: string }>;
  };
};

export const DeleteTaskDocument = `
    mutation DeleteTask($taskId: uuid!) {
  delete_tasks_by_pk(id: $taskId) {
    id
  }
}
    ` as string & TypedDocumentNode<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const UpdateTaskByIdDocument = `
    mutation UpdateTaskById($taskId: uuid!, $updatePayload: tasks_set_input!) {
  update_tasks_by_pk(pk_columns: {id: $taskId}, _set: $updatePayload) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdateTaskByIdMutation, UpdateTaskByIdMutationVariables>;
export const CreateTaskDocument = `
    mutation CreateTask($input: tasks_insert_input!) {
  insert_tasks_one(object: $input) {
    id
  }
}
    ` as string & TypedDocumentNode<CreateTaskMutation, CreateTaskMutationVariables>;
export const CreateTaskOwnerDocument = `
    mutation CreateTaskOwner($taskId: uuid!, $ownerId: uuid!) {
  delete_task_owners(where: {task_id: {_eq: $taskId}}) {
    returning {
      owner_id
    }
  }
  insert_task_owners_one(object: {task_id: $taskId, owner_id: $ownerId}) {
    owner_id
  }
}
    ` as string & TypedDocumentNode<CreateTaskOwnerMutation, CreateTaskOwnerMutationVariables>;
export const RemoveTaskOwnerDocument = `
    mutation RemoveTaskOwner($taskId: uuid!) {
  delete_task_owners(where: {task_id: {_eq: $taskId}}) {
    returning {
      owner_id
    }
  }
}
    ` as string & TypedDocumentNode<RemoveTaskOwnerMutation, RemoveTaskOwnerMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteTask: build.mutation<DeleteTaskMutation, DeleteTaskMutationVariables>({
      query: (variables) => ({ document: DeleteTaskDocument, variables }),
    }),
    UpdateTaskById: build.mutation<UpdateTaskByIdMutation, UpdateTaskByIdMutationVariables>({
      query: (variables) => ({ document: UpdateTaskByIdDocument, variables }),
    }),
    CreateTask: build.mutation<CreateTaskMutation, CreateTaskMutationVariables>({
      query: (variables) => ({ document: CreateTaskDocument, variables }),
    }),
    CreateTaskOwner: build.mutation<CreateTaskOwnerMutation, CreateTaskOwnerMutationVariables>({
      query: (variables) => ({ document: CreateTaskOwnerDocument, variables }),
    }),
    RemoveTaskOwner: build.mutation<RemoveTaskOwnerMutation, RemoveTaskOwnerMutationVariables>({
      query: (variables) => ({ document: RemoveTaskOwnerDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useDeleteTaskMutation,
  useUpdateTaskByIdMutation,
  useCreateTaskMutation,
  useCreateTaskOwnerMutation,
  useRemoveTaskOwnerMutation,
} = injectedRtkApi;
