import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateManualFindingInput, Finding_Types_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  ButtonTab,
  ButtonTabList,
  ButtonTabs,
  createEnumTabsControl,
  errorToast,
  successToast,
} from '@main/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useCreateManualFindingMutation } from './create-finding.generated';

export type CreateFindingModalProps = {
  controlId: string;
  isOpen: boolean;
  onClose(): void;
};

const schema = z.object({
  title: z.string().trim().min(1),
  description: z.string().trim(),
});

type Schema = z.infer<typeof schema>;

const defaultValues: Schema = {
  title: '',
  description: '',
};

const DEFAULT_FINDING_TYPE = Finding_Types_Enum.Issue;
const findingTypeTabsControl = createEnumTabsControl([
  DEFAULT_FINDING_TYPE,
  Finding_Types_Enum.Recommendation,
  Finding_Types_Enum.Consideration,
]);

export const CreateFindingModal = ({ controlId, isOpen, onClose }: CreateFindingModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('controls.createFindingModal.heading')}</ModalHeader>
        <ModalCloseButton />
        <CreateFindingForm controlId={controlId} onClose={onClose} />
      </ModalContent>
    </Modal>
  );
};

const CreateFindingForm = ({ controlId, onClose }: { controlId: string; onClose(): void }) => {
  const { t } = useTranslation();

  const [findingType, setFindingType] = useState(DEFAULT_FINDING_TYPE);

  const { register, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const createManualFinding = useCreateManualFinding();

  const onSubmit = handleSubmit(async ({ title, description }) => {
    await createManualFinding({
      type: findingType,
      title,
      description,
      controlId,
    });
    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <Flex direction="column" gap={6}>
          <FormControl isInvalid={!!formState.errors.title}>
            <FormLabel display="flex" alignItems="center" gap={1}>
              <Text>{t('controls.createFindingModal.fields.type.label')}:</Text>
              <TypeTooltip />
            </FormLabel>
            <ButtonTabs {...findingTypeTabsControl(findingType, setFindingType)}>
              <ButtonTabList>
                <ButtonTab>{t('findings.type.issue')}</ButtonTab>
                <ButtonTab>{t('findings.type.recommendation')}</ButtonTab>
                <ButtonTab>{t('findings.type.consideration')}</ButtonTab>
              </ButtonTabList>
            </ButtonTabs>
          </FormControl>

          <FormControl isInvalid={!!formState.errors.title}>
            <FormLabel>{t('controls.createFindingModal.fields.title.label')}:</FormLabel>
            <Input
              placeholder={t('controls.createFindingModal.fields.title.placeholder')}
              {...register('title')}
            />
            <FormErrorMessage>{formState.errors.title?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formState.errors.description}>
            <FormLabel>{t('controls.createFindingModal.fields.description.label')}:</FormLabel>
            <Textarea
              placeholder={t('controls.createFindingModal.fields.description.placeholder')}
              {...register('description')}
            />
            <FormErrorMessage>{formState.errors.description?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button isDisabled={formState.isSubmitting} onClick={onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button type="submit" colorScheme="blue" isLoading={formState.isSubmitting}>
            {t('buttons.confirm')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

const TypeTooltip = () => {
  const { t } = useTranslation();

  const label = t('controls.createFindingModal.fields.type.tooltip', {
    issueName: t('findings.type.issue'),
    issueStatus: t('controls.status.enums.Failing'),
    recommendationName: t('findings.type.recommendation'),
    recommendationStatus: t('controls.status.enums.At risk'),
    considerationName: t('findings.type.consideration'),
  });

  return (
    <Tooltip label={label} whiteSpace="pre-wrap" placement="right" hasArrow>
      <Icon as={InformationCircleIcon} w={4} h={4} />
    </Tooltip>
  );
};

function useCreateManualFinding() {
  const { t } = useTranslation();

  const [createManualFinding] = useCreateManualFindingMutation();

  return async (input: CreateManualFindingInput) => {
    try {
      await createManualFinding({ input }).unwrap();
      successToast(t('successMessages.createSucceeded', { entity: t('entities.finding') }));
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.finding') }));
      datadogLogs.logger.error('Policy export failed', input, toError(error));
    }
  };
}
