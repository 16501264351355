/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetClientQquestionHistoryQueryVariables = Types.Exact<{
  questionId: Types.Scalars['uuid']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetClientQquestionHistoryQuery = {
  __typename?: 'query_root';
  question?: {
    __typename?: 'client_questionnaire_questions';
    id: string;
    answers: Array<{
      __typename?: 'client_questionnaire_answers';
      id: string;
      answer: string;
      comment: string;
      created_at: string;
      is_ai_generated: boolean;
      updatedBy?: { __typename?: 'users'; id: string; displayName: string };
    }>;
  };
};

export type ClientQquestionAnswerHistoryFragment = {
  __typename?: 'client_questionnaire_answers';
  id: string;
  answer: string;
  comment: string;
  created_at: string;
  is_ai_generated: boolean;
  updatedBy?: { __typename?: 'users'; id: string; displayName: string };
};

export const ClientQquestionAnswerHistoryFragmentDoc = `
    fragment ClientQquestionAnswerHistory on client_questionnaire_answers {
  id
  answer
  comment
  created_at
  is_ai_generated
  updatedBy: user {
    id
    displayName
  }
}
    `;
export const GetClientQquestionHistoryDocument = `
    query GetClientQquestionHistory($questionId: uuid!, $offset: Int, $limit: Int) {
  question: client_questionnaire_questions_by_pk(id: $questionId) {
    id
    answers: client_questionnaire_answers(
      order_by: {created_at: desc}
      offset: $offset
      limit: $limit
    ) {
      ...ClientQquestionAnswerHistory
    }
  }
}
    ${ClientQquestionAnswerHistoryFragmentDoc}` as string &
  TypedDocumentNode<GetClientQquestionHistoryQuery, GetClientQquestionHistoryQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetClientQquestionHistory: build.query<
      GetClientQquestionHistoryQuery,
      GetClientQquestionHistoryQueryVariables
    >({
      query: (variables) => ({ document: GetClientQquestionHistoryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetClientQquestionHistoryQuery, useLazyGetClientQquestionHistoryQuery } =
  injectedRtkApi;
