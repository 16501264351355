import { Client_Questionnaire_Status_Enum } from '@main/graphql/types.generated';
import { maxTextLength, useStableCallback } from '@main/shared/utils';
import { Trans, useAlertDialog, useDownloadStorageFile } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useExportClientQuestionnaireMutation } from './questionnaire-questions.generated';
import { UseExportClientQuestionnaireFragment } from './use-export.generated';

export function useExportClientQ() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const downloadFile = useDownloadStorageFile();
  const [exportClientQuestionnaire] = useExportClientQuestionnaireMutation();

  async function exportQuestionnaire(questionnaire: UseExportClientQuestionnaireFragment) {
    let exportFileId = questionnaire.export_file_id;

    if (!exportFileId) {
      const { exportFile } = await exportClientQuestionnaire({
        questionnaireId: questionnaire.id,
      }).unwrap();

      if (!exportFile?.file_id) {
        throw new Error('No export file id returned');
      }

      exportFileId = exportFile.file_id;
    }

    await downloadFile(exportFileId);

    return exportFileId;
  }

  return useStableCallback(async (questionnaire: UseExportClientQuestionnaireFragment) => {
    if (questionnaire.status === Client_Questionnaire_Status_Enum.Complete) {
      return exportQuestionnaire(questionnaire);
    }

    return openDialog({
      dialogHeader: t('clientQuestionnaire.alert.incompleteExport.header'),
      dialogContent: (
        <Trans
          i18nKey="clientQuestionnaire.alert.incompleteExport.content"
          values={{ name: maxTextLength(questionnaire?.name ?? '') }}
        />
      ),
      confirmAction: {
        children: t('buttons.export'),
        colorScheme: 'blue',
        onClick: () => exportQuestionnaire(questionnaire),
      },
    });
  });
}
