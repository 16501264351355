/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { FieldValueFragmentDoc } from '../../../../../libs/graphql/src/fragments/FieldValueFragments.generated';
import { PolicyDrawerFragmentFragmentDoc } from './policy.fragment.generated';
import { CommentsFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/comments.fragment.generated';
import { api } from '@main/graphql/shared-api';
export type UserApprovalCountFragment = {
  __typename?: 'policy_approvals';
  total_users: {
    __typename?: 'policy_approval_users_aggregate';
    aggregate?: { __typename?: 'policy_approval_users_aggregate_fields'; count: number };
  };
  approved_users: {
    __typename?: 'policy_approval_users_aggregate';
    aggregate?: { __typename?: 'policy_approval_users_aggregate_fields'; count: number };
  };
};

export type UserAcknowledgementCountFragment = {
  __typename?: 'policy_acknowledgements';
  total_users: {
    __typename?: 'policy_acknowledgement_users_aggregate';
    aggregate?: { __typename?: 'policy_acknowledgement_users_aggregate_fields'; count: number };
  };
  acknowledged_users: {
    __typename?: 'policy_acknowledgement_users_aggregate';
    aggregate?: { __typename?: 'policy_acknowledgement_users_aggregate_fields'; count: number };
  };
};

export type GetPoliciesQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
}>;

export type GetPoliciesQuery = {
  __typename?: 'query_root';
  policies: Array<{
    __typename?: 'policies';
    id: string;
    name?: string;
    type: Types.Policy_Types_Enum;
    status: Types.Policy_Statuses_Enum;
    created_at: string;
    internal_id?: string;
    description?: string;
    assignee_id?: string;
    policy_approvers: Array<{
      __typename?: 'policy_approvers';
      id: string;
      user: { __typename?: 'users'; id: string; displayName: string };
    }>;
    policy_versions: Array<{
      __typename?: 'policy_versions';
      id: string;
      status: Types.Policy_Version_Statuses_Enum;
      created_at: string;
      validity_start?: string;
      policy_approvals: Array<{
        __typename?: 'policy_approvals';
        id: string;
        status: Types.Policy_Approval_Statuses_Enum;
        total_users: {
          __typename?: 'policy_approval_users_aggregate';
          aggregate?: { __typename?: 'policy_approval_users_aggregate_fields'; count: number };
        };
        approved_users: {
          __typename?: 'policy_approval_users_aggregate';
          aggregate?: { __typename?: 'policy_approval_users_aggregate_fields'; count: number };
        };
      }>;
      policy_acknowledgements: Array<{
        __typename?: 'policy_acknowledgements';
        id: string;
        status: Types.Policy_Acknowledgement_Statuses_Enum;
        total_users: {
          __typename?: 'policy_acknowledgement_users_aggregate';
          aggregate?: {
            __typename?: 'policy_acknowledgement_users_aggregate_fields';
            count: number;
          };
        };
        acknowledged_users: {
          __typename?: 'policy_acknowledgement_users_aggregate';
          aggregate?: {
            __typename?: 'policy_acknowledgement_users_aggregate_fields';
            count: number;
          };
        };
      }>;
    }>;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
      date_field_value?: {
        __typename?: 'date_field_values';
        field_value_id: string;
        value: string;
      };
      number_field_value?: {
        __typename?: 'number_field_values';
        field_value_id: string;
        value: number;
      };
      email_field_value?: {
        __typename?: 'email_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  }>;
};

export type GetPolicyQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type GetPolicyQuery = {
  __typename?: 'query_root';
  policies_by_pk?: {
    __typename?: 'policies';
    id: string;
    name?: string;
    type: Types.Policy_Types_Enum;
    status: Types.Policy_Statuses_Enum;
    internal_id?: string;
    description?: string;
    created_at: string;
    organization_id: string;
    assignee_id?: string;
    policies_comments: Array<{
      __typename?: 'comments';
      id: string;
      text: string;
      created_at: string;
      updated_at: string;
      user_id: string;
      comments_comment_uploads: Array<{
        __typename?: 'comment_uploads';
        id: string;
        comment_uploads_file: {
          __typename?: 'files';
          id: string;
          name?: string;
          size?: number;
          createdAt: string;
        };
      }>;
    }>;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
      date_field_value?: {
        __typename?: 'date_field_values';
        field_value_id: string;
        value: string;
      };
      number_field_value?: {
        __typename?: 'number_field_values';
        field_value_id: string;
        value: number;
      };
      email_field_value?: {
        __typename?: 'email_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
    policy_versions: Array<{
      __typename?: 'policy_versions';
      id: string;
      version_id: number;
      status: Types.Policy_Version_Statuses_Enum;
      policy_text?: string;
      created_at: string;
      validity_start?: string;
      created_by?: string;
      revision_details?: string;
      file?: { __typename?: 'files'; id: string; name?: string; size?: number };
      policy_approvals: Array<{
        __typename?: 'policy_approvals';
        id: string;
        status: Types.Policy_Approval_Statuses_Enum;
        due_date: string;
        created_by: string;
        policy_approval_users: Array<{
          __typename?: 'policy_approval_users';
          id: string;
          user_id: string;
          approved_at?: string;
          policy_approval_id: string;
        }>;
      }>;
      policy_acknowledgements: Array<{
        __typename?: 'policy_acknowledgements';
        id: string;
        status: Types.Policy_Acknowledgement_Statuses_Enum;
        due_date: string;
        created_by: string;
        policy_acknowledgement_users: Array<{
          __typename?: 'policy_acknowledgement_users';
          id: string;
          user_id: string;
          acknowledged_at?: string;
          policy_acknowledgement_id: string;
        }>;
      }>;
    }>;
    policy_approvers: Array<{
      __typename?: 'policy_approvers';
      id: string;
      user: { __typename?: 'users'; id: string; displayName: string };
    }>;
  };
};

export type CreatePolicyMutationVariables = Types.Exact<{
  input: Types.Policies_Insert_Input;
}>;

export type CreatePolicyMutation = {
  __typename?: 'mutation_root';
  insert_policies_one?: { __typename?: 'policies'; id: string };
};

export type UpdatePolicyMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  updatePayload: Types.Policies_Set_Input;
}>;

export type UpdatePolicyMutation = {
  __typename?: 'mutation_root';
  update_policies_by_pk?: { __typename?: 'policies'; id: string };
};

export type DeletePolicyMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeletePolicyMutation = {
  __typename?: 'mutation_root';
  delete_policies_by_pk?: { __typename?: 'policies'; id: string };
};

export type DeletePolicyVersionMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeletePolicyVersionMutation = {
  __typename?: 'mutation_root';
  delete_policy_versions_by_pk?: { __typename?: 'policy_versions'; id: string };
};

export type AddPolicyApproverMutationVariables = Types.Exact<{
  input: Types.Policy_Approvers_Insert_Input;
}>;

export type AddPolicyApproverMutation = {
  __typename?: 'mutation_root';
  insert_policy_approvers_one?: { __typename?: 'policy_approvers'; id: string };
};

export type DeletePolicyApproverMutationVariables = Types.Exact<{
  approverId: Types.Scalars['uuid']['input'];
}>;

export type DeletePolicyApproverMutation = {
  __typename?: 'mutation_root';
  delete_policy_approvers_by_pk?: { __typename?: 'policy_approvers'; id: string };
};

export type GetPolicyActivityHistoryQueryVariables = Types.Exact<{
  policy_id: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetPolicyActivityHistoryQuery = {
  __typename?: 'query_root';
  audit_policy_history_view: Array<{
    __typename?: 'audit_policy_history_view';
    user_id?: string;
    action_timestamp?: string;
    action: ClientTypes.AuditTableActions;
    table_name: ClientTypes.PolicyHistoryTables;
    hasura_user: ClientTypes.AuditTableHasuraUser;
    changed_fields?: ClientTypes.PolicyChangedFields;
    row_data: ClientTypes.PolicyRowData;
  }>;
};

export type UpdatePolicyVersionMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  updatePayload: Types.Policy_Versions_Set_Input;
}>;

export type UpdatePolicyVersionMutation = {
  __typename?: 'mutation_root';
  update_policy_versions_by_pk?: { __typename?: 'policy_versions'; id: string };
};

export type UpdatePolicyVersionContentMutationVariables = Types.Exact<{
  policyVersionId: Types.Scalars['uuid']['input'];
  fileId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  policyText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type UpdatePolicyVersionContentMutation = {
  __typename?: 'mutation_root';
  update_policy_version_content?: { __typename?: 'GeneralActionOutput'; message: string };
};

export type CreatePolicyVersionMutationVariables = Types.Exact<{
  input: Types.Policy_Versions_Insert_Input;
}>;

export type CreatePolicyVersionMutation = {
  __typename?: 'mutation_root';
  insert_policy_versions_one?: { __typename?: 'policy_versions'; id: string };
};

export type CreatePolicyApprovalMutationVariables = Types.Exact<{
  input: Types.Policy_Approvals_Insert_Input;
}>;

export type CreatePolicyApprovalMutation = {
  __typename?: 'mutation_root';
  insert_policy_approvals_one?: { __typename?: 'policy_approvals'; id: string };
};

export type UpdateApprovalUsersMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  updatePayload: Types.Policy_Approval_Users_Set_Input;
}>;

export type UpdateApprovalUsersMutation = {
  __typename?: 'mutation_root';
  update_policy_approval_users_by_pk?: { __typename?: 'policy_approval_users'; id: string };
};

export type AcknowledgePolicyAcknowledgementsMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  acknowledgementIds: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
  acknowledged_at: Types.Scalars['timestamptz']['input'];
}>;

export type AcknowledgePolicyAcknowledgementsMutation = {
  __typename?: 'mutation_root';
  update_policy_acknowledgement_users?: {
    __typename?: 'policy_acknowledgement_users_mutation_response';
    affected_rows: number;
  };
};

export type GetPolicyAcknowledgersQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid']['input'];
}>;

export type GetPolicyAcknowledgersQuery = {
  __typename?: 'query_root';
  organization_users: Array<{
    __typename?: 'organization_users';
    user: { __typename?: 'users'; id: string; displayName: string };
  }>;
};

export type CreatePolicyAcknowledgementMutationVariables = Types.Exact<{
  input: Types.Policy_Acknowledgements_Insert_Input;
}>;

export type CreatePolicyAcknowledgementMutation = {
  __typename?: 'mutation_root';
  insert_policy_acknowledgements_one?: { __typename?: 'policy_acknowledgements'; id: string };
};

export type SendApprovalReminderMutationVariables = Types.Exact<{
  input: Types.ApprovalReminderInput;
}>;

export type SendApprovalReminderMutation = {
  __typename?: 'mutation_root';
  send_approval_reminder?: {
    __typename?: 'ApprovalReminderOutput';
    message: string;
    status: number;
  };
};

export const UserApprovalCountFragmentDoc = `
    fragment UserApprovalCount on policy_approvals {
  total_users: policy_approval_users_aggregate {
    aggregate {
      count
    }
  }
  approved_users: policy_approval_users_aggregate(
    where: {approved_at: {_is_null: false}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const UserAcknowledgementCountFragmentDoc = `
    fragment UserAcknowledgementCount on policy_acknowledgements {
  total_users: policy_acknowledgement_users_aggregate {
    aggregate {
      count
    }
  }
  acknowledged_users: policy_acknowledgement_users_aggregate(
    where: {acknowledged_at: {_is_null: false}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetPoliciesDocument = `
    query GetPolicies($orgId: uuid!) {
  policies(where: {organization_id: {_eq: $orgId}}, order_by: {internal_id: asc}) {
    id
    name
    type
    status
    created_at
    internal_id
    description
    assignee_id
    policy_approvers {
      id
      user {
        id
        displayName
      }
    }
    policy_versions(
      where: {status: {_in: [Draft, Approved, Awaiting_approval, Retired]}}
      order_by: {created_at: desc}
      limit: 4
    ) {
      id
      status
      created_at
      validity_start
      policy_approvals {
        id
        status
        ...UserApprovalCount
      }
      policy_acknowledgements {
        id
        status
        ...UserAcknowledgementCount
      }
    }
    field_values {
      ...FieldValue
    }
  }
}
    ${UserApprovalCountFragmentDoc}
${UserAcknowledgementCountFragmentDoc}
${FieldValueFragmentDoc}` as string &
  TypedDocumentNode<GetPoliciesQuery, GetPoliciesQueryVariables>;
export const GetPolicyDocument = `
    query GetPolicy($id: uuid!) {
  policies_by_pk(id: $id) {
    ...PolicyDrawerFragment
    policies_comments(order_by: {created_at: asc}) {
      ...CommentsFragment
    }
    field_values {
      ...FieldValue
    }
  }
}
    ${PolicyDrawerFragmentFragmentDoc}
${CommentsFragmentFragmentDoc}
${FieldValueFragmentDoc}` as string & TypedDocumentNode<GetPolicyQuery, GetPolicyQueryVariables>;
export const CreatePolicyDocument = `
    mutation CreatePolicy($input: policies_insert_input!) {
  insert_policies_one(object: $input) {
    id
  }
}
    ` as string & TypedDocumentNode<CreatePolicyMutation, CreatePolicyMutationVariables>;
export const UpdatePolicyDocument = `
    mutation UpdatePolicy($id: uuid!, $updatePayload: policies_set_input!) {
  update_policies_by_pk(pk_columns: {id: $id}, _set: $updatePayload) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdatePolicyMutation, UpdatePolicyMutationVariables>;
export const DeletePolicyDocument = `
    mutation DeletePolicy($id: uuid!) {
  delete_policies_by_pk(id: $id) {
    id
  }
}
    ` as string & TypedDocumentNode<DeletePolicyMutation, DeletePolicyMutationVariables>;
export const DeletePolicyVersionDocument = `
    mutation DeletePolicyVersion($id: uuid!) {
  delete_policy_versions_by_pk(id: $id) {
    id
  }
}
    ` as string &
  TypedDocumentNode<DeletePolicyVersionMutation, DeletePolicyVersionMutationVariables>;
export const AddPolicyApproverDocument = `
    mutation AddPolicyApprover($input: policy_approvers_insert_input!) {
  insert_policy_approvers_one(object: $input) {
    id
  }
}
    ` as string & TypedDocumentNode<AddPolicyApproverMutation, AddPolicyApproverMutationVariables>;
export const DeletePolicyApproverDocument = `
    mutation DeletePolicyApprover($approverId: uuid!) {
  delete_policy_approvers_by_pk(id: $approverId) {
    id
  }
}
    ` as string &
  TypedDocumentNode<DeletePolicyApproverMutation, DeletePolicyApproverMutationVariables>;
export const GetPolicyActivityHistoryDocument = `
    query GetPolicyActivityHistory($policy_id: uuid!, $limit: Int = 10, $offset: Int = 0) {
  audit_policy_history_view(
    limit: $limit
    offset: $offset
    where: {policy_id: {_eq: $policy_id}}
    order_by: {action_timestamp: desc}
  ) {
    user_id
    action_timestamp
    action
    table_name
    hasura_user
    changed_fields
    row_data
  }
}
    ` as string &
  TypedDocumentNode<GetPolicyActivityHistoryQuery, GetPolicyActivityHistoryQueryVariables>;
export const UpdatePolicyVersionDocument = `
    mutation UpdatePolicyVersion($id: uuid!, $updatePayload: policy_versions_set_input!) {
  update_policy_versions_by_pk(pk_columns: {id: $id}, _set: $updatePayload) {
    id
  }
}
    ` as string &
  TypedDocumentNode<UpdatePolicyVersionMutation, UpdatePolicyVersionMutationVariables>;
export const UpdatePolicyVersionContentDocument = `
    mutation UpdatePolicyVersionContent($policyVersionId: uuid!, $fileId: uuid, $policyText: String) {
  update_policy_version_content(
    policy_version_id: $policyVersionId
    policy_text: $policyText
    file_id: $fileId
  ) {
    message
  }
}
    ` as string &
  TypedDocumentNode<
    UpdatePolicyVersionContentMutation,
    UpdatePolicyVersionContentMutationVariables
  >;
export const CreatePolicyVersionDocument = `
    mutation CreatePolicyVersion($input: policy_versions_insert_input!) {
  insert_policy_versions_one(object: $input) {
    id
  }
}
    ` as string &
  TypedDocumentNode<CreatePolicyVersionMutation, CreatePolicyVersionMutationVariables>;
export const CreatePolicyApprovalDocument = `
    mutation CreatePolicyApproval($input: policy_approvals_insert_input!) {
  insert_policy_approvals_one(object: $input) {
    id
  }
}
    ` as string &
  TypedDocumentNode<CreatePolicyApprovalMutation, CreatePolicyApprovalMutationVariables>;
export const UpdateApprovalUsersDocument = `
    mutation UpdateApprovalUsers($id: uuid!, $updatePayload: policy_approval_users_set_input!) {
  update_policy_approval_users_by_pk(pk_columns: {id: $id}, _set: $updatePayload) {
    id
  }
}
    ` as string &
  TypedDocumentNode<UpdateApprovalUsersMutation, UpdateApprovalUsersMutationVariables>;
export const AcknowledgePolicyAcknowledgementsDocument = `
    mutation AcknowledgePolicyAcknowledgements($userId: uuid!, $acknowledgementIds: [uuid!]!, $acknowledged_at: timestamptz!) {
  update_policy_acknowledgement_users(
    where: {user_id: {_eq: $userId}, policy_acknowledgement_id: {_in: $acknowledgementIds}}
    _set: {acknowledged_at: $acknowledged_at}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    AcknowledgePolicyAcknowledgementsMutation,
    AcknowledgePolicyAcknowledgementsMutationVariables
  >;
export const GetPolicyAcknowledgersDocument = `
    query GetPolicyAcknowledgers($orgId: uuid!) {
  organization_users(
    where: {organization_id: {_eq: $orgId}, disabled: {_eq: false}, role: {role_permissions: {permission: {_eq: acknowledge_policies}}}}
  ) {
    user {
      id
      displayName
    }
  }
}
    ` as string &
  TypedDocumentNode<GetPolicyAcknowledgersQuery, GetPolicyAcknowledgersQueryVariables>;
export const CreatePolicyAcknowledgementDocument = `
    mutation CreatePolicyAcknowledgement($input: policy_acknowledgements_insert_input!) {
  insert_policy_acknowledgements_one(object: $input) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    CreatePolicyAcknowledgementMutation,
    CreatePolicyAcknowledgementMutationVariables
  >;
export const SendApprovalReminderDocument = `
    mutation SendApprovalReminder($input: ApprovalReminderInput!) {
  send_approval_reminder(input: $input) {
    message
    status
  }
}
    ` as string &
  TypedDocumentNode<SendApprovalReminderMutation, SendApprovalReminderMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPolicies: build.query<GetPoliciesQuery, GetPoliciesQueryVariables>({
      query: (variables) => ({ document: GetPoliciesDocument, variables }),
    }),
    GetPolicy: build.query<GetPolicyQuery, GetPolicyQueryVariables>({
      query: (variables) => ({ document: GetPolicyDocument, variables }),
    }),
    CreatePolicy: build.mutation<CreatePolicyMutation, CreatePolicyMutationVariables>({
      query: (variables) => ({ document: CreatePolicyDocument, variables }),
    }),
    UpdatePolicy: build.mutation<UpdatePolicyMutation, UpdatePolicyMutationVariables>({
      query: (variables) => ({ document: UpdatePolicyDocument, variables }),
    }),
    DeletePolicy: build.mutation<DeletePolicyMutation, DeletePolicyMutationVariables>({
      query: (variables) => ({ document: DeletePolicyDocument, variables }),
    }),
    DeletePolicyVersion: build.mutation<
      DeletePolicyVersionMutation,
      DeletePolicyVersionMutationVariables
    >({
      query: (variables) => ({ document: DeletePolicyVersionDocument, variables }),
    }),
    AddPolicyApprover: build.mutation<
      AddPolicyApproverMutation,
      AddPolicyApproverMutationVariables
    >({
      query: (variables) => ({ document: AddPolicyApproverDocument, variables }),
    }),
    DeletePolicyApprover: build.mutation<
      DeletePolicyApproverMutation,
      DeletePolicyApproverMutationVariables
    >({
      query: (variables) => ({ document: DeletePolicyApproverDocument, variables }),
    }),
    GetPolicyActivityHistory: build.query<
      GetPolicyActivityHistoryQuery,
      GetPolicyActivityHistoryQueryVariables
    >({
      query: (variables) => ({ document: GetPolicyActivityHistoryDocument, variables }),
    }),
    UpdatePolicyVersion: build.mutation<
      UpdatePolicyVersionMutation,
      UpdatePolicyVersionMutationVariables
    >({
      query: (variables) => ({ document: UpdatePolicyVersionDocument, variables }),
    }),
    UpdatePolicyVersionContent: build.mutation<
      UpdatePolicyVersionContentMutation,
      UpdatePolicyVersionContentMutationVariables
    >({
      query: (variables) => ({ document: UpdatePolicyVersionContentDocument, variables }),
    }),
    CreatePolicyVersion: build.mutation<
      CreatePolicyVersionMutation,
      CreatePolicyVersionMutationVariables
    >({
      query: (variables) => ({ document: CreatePolicyVersionDocument, variables }),
    }),
    CreatePolicyApproval: build.mutation<
      CreatePolicyApprovalMutation,
      CreatePolicyApprovalMutationVariables
    >({
      query: (variables) => ({ document: CreatePolicyApprovalDocument, variables }),
    }),
    UpdateApprovalUsers: build.mutation<
      UpdateApprovalUsersMutation,
      UpdateApprovalUsersMutationVariables
    >({
      query: (variables) => ({ document: UpdateApprovalUsersDocument, variables }),
    }),
    AcknowledgePolicyAcknowledgements: build.mutation<
      AcknowledgePolicyAcknowledgementsMutation,
      AcknowledgePolicyAcknowledgementsMutationVariables
    >({
      query: (variables) => ({ document: AcknowledgePolicyAcknowledgementsDocument, variables }),
    }),
    GetPolicyAcknowledgers: build.query<
      GetPolicyAcknowledgersQuery,
      GetPolicyAcknowledgersQueryVariables
    >({
      query: (variables) => ({ document: GetPolicyAcknowledgersDocument, variables }),
    }),
    CreatePolicyAcknowledgement: build.mutation<
      CreatePolicyAcknowledgementMutation,
      CreatePolicyAcknowledgementMutationVariables
    >({
      query: (variables) => ({ document: CreatePolicyAcknowledgementDocument, variables }),
    }),
    SendApprovalReminder: build.mutation<
      SendApprovalReminderMutation,
      SendApprovalReminderMutationVariables
    >({
      query: (variables) => ({ document: SendApprovalReminderDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPoliciesQuery,
  useLazyGetPoliciesQuery,
  useGetPolicyQuery,
  useLazyGetPolicyQuery,
  useCreatePolicyMutation,
  useUpdatePolicyMutation,
  useDeletePolicyMutation,
  useDeletePolicyVersionMutation,
  useAddPolicyApproverMutation,
  useDeletePolicyApproverMutation,
  useGetPolicyActivityHistoryQuery,
  useLazyGetPolicyActivityHistoryQuery,
  useUpdatePolicyVersionMutation,
  useUpdatePolicyVersionContentMutation,
  useCreatePolicyVersionMutation,
  useCreatePolicyApprovalMutation,
  useUpdateApprovalUsersMutation,
  useAcknowledgePolicyAcknowledgementsMutation,
  useGetPolicyAcknowledgersQuery,
  useLazyGetPolicyAcknowledgersQuery,
  useCreatePolicyAcknowledgementMutation,
  useSendApprovalReminderMutation,
} = injectedRtkApi;
