import { createGqlSubscriptionApi } from '@main/graphql/create-subscription-api';

import { api, GetClientQuestionnaireQuestionsDocument } from './questionnaire-questions.generated';

export const {
  useGetClientQuestionnaireQuestionsQuery: useGetClientQuestionnaireQuestionsSubscription,
} = createGqlSubscriptionApi(
  api,
  'GetClientQuestionnaireQuestions',
  GetClientQuestionnaireQuestionsDocument,
);
