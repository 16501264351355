import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { toError } from '@main/shared/utils';
import { AutoResizeTextarea, EditableAvatar, errorToast, successToast } from '@main/ui';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { dateToMidnightUTC, toDatePart } from '../../utils/date';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import { getCurrentOrgNonDisabledUsers } from '../user/slice';
import { useGetClientQuestionnaireQuestionsQuery } from './questionnaire-questions.generated';
import { useUpdateClientQuestionnaireTableMutation } from './table.generated';

const formSchema = z.object({
  name: z.string().trim().min(1),
  company: z.string().trim().min(1),
  owner: z.object({
    id: z.string().trim().min(1),
    displayName: z.string().trim().min(1),
  }),
  due_date: z.string().min(1).transform(dateToMidnightUTC),
  description: z.string().trim().optional(),
});

export interface EditClientQuestionnaireModalProps {
  questionnaireId: string;
  modal: Omit<ModalProps, 'children'>;
}

export function EditClientQuestionnaireModal(props: EditClientQuestionnaireModalProps) {
  const { t } = useTranslation();

  return (
    <Modal size="xl" isCentered motionPreset="slideInBottom" {...props.modal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          {t('editQuestionnaireModal.heading')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function Form({ questionnaireId, modal }: EditClientQuestionnaireModalProps) {
  const { t } = useTranslation();
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const { data, refetch } = useGetClientQuestionnaireQuestionsQuery(
    { questionnaireId },
    { refetchOnMountOrArgChange: true },
  );
  const questionnaire = useMemo(
    () => ({
      ...data?.clientQuestionnaire,
      due_date: toDatePart(data?.clientQuestionnaire?.due_date),
    }),
    [data?.clientQuestionnaire],
  );
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: questionnaire,
  });
  useEffect(() => form.reset(questionnaire, { keepDirtyValues: true }), [form, questionnaire]);

  const [updateClientQuestionnaire] = useUpdateClientQuestionnaireTableMutation();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      await updateClientQuestionnaire({
        questionnaireId,
        input: {
          name: data.name,
          company: data.company,
          owner_id: data.owner.id,
          due_date: data.due_date,
          description: data.description,
        },
      }).unwrap();
      successToast(t('clientQuestionnaires.updateSuccessful'));
      refetch();
      modal.onClose();
    } catch (error) {
      datadogLogs.logger.error(
        'Failed to update client questionnaire',
        { questionnaireId },
        toError(error),
      );
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.clientQuestionnaire') }));
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="6">
        <FormControl isInvalid={!!form.formState.errors.name}>
          <FormLabel>{t('createQuestionnaireModal.form.name.label')}:</FormLabel>
          <Input
            {...form.register('name')}
            placeholder={t('createQuestionnaireModal.form.name.placeholder')}
          />
          <FormErrorMessage>{t('clientQuestionnaires.formErrorMessages.name')}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors.company}>
          <FormLabel>{t('createQuestionnaireModal.form.company.label')}:</FormLabel>
          <Input
            {...form.register('company')}
            placeholder={t('createQuestionnaireModal.form.company.placeholder')}
          />
          <FormErrorMessage>{t('clientQuestionnaires.formErrorMessages.company')}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors.owner}>
          <FormLabel>{t('createQuestionnaireModal.form.owner.label')}:</FormLabel>
          <Controller
            name="owner"
            control={form.control}
            render={({ field }) => (
              <EditableAvatar
                isClearable={false}
                options={getAssigneeOptions(currentOrgNonDisabledUsers)}
                value={field.value}
                placeholder={t('createQuestionnaireModal.form.owner.placeholder')}
                onChange={field.onChange}
              />
            )}
          />
          <FormErrorMessage>{t('clientQuestionnaires.formErrorMessages.owner')}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors.due_date}>
          <FormLabel>{t('createQuestionnaireModal.form.dueDate.label')}:</FormLabel>
          <Input
            {...form.register('due_date')}
            type="date"
            placeholder={t('createQuestionnaireModal.form.dueDate.placeholder')}
          />
          <FormErrorMessage>{t('clientQuestionnaires.formErrorMessages.dueDate')}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors.description}>
          <FormLabel>{t('createQuestionnaireModal.form.description.label')}:</FormLabel>
          <AutoResizeTextarea
            {...form.register('description')}
            placeholder={t('createQuestionnaireModal.form.description.placeholder')}
            minRows={3}
          />
          <FormErrorMessage>{form.formState.errors.description?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup justifyContent="flex-end" pb="2">
          <Button isDisabled={form.formState.isSubmitting} onClick={modal.onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button
            type="submit"
            colorScheme="blue"
            isLoading={form.formState.isSubmitting}
            isDisabled={!form.formState.isDirty}
          >
            {t('buttons.submit')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
}
