/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type ClientQuestionnaireQuestionTableFragment = {
  __typename?: 'client_questionnaire_questions';
  id: string;
  question: string;
  status: Types.Client_Questionnaire_Question_Status_Enum;
  metadata?: ClientTypes.ClientQquestionMetadata;
  answer: Array<{
    __typename?: 'client_questionnaire_answers';
    id: string;
    answer: string;
    comment: string;
    is_ai_generated: boolean;
  }>;
};

export const ClientQuestionnaireQuestionTableFragmentDoc = `
    fragment ClientQuestionnaireQuestionTable on client_questionnaire_questions {
  id
  question
  status
  metadata
  answer: client_questionnaire_answers(order_by: {created_at: desc}, limit: 1) {
    id
    answer
    comment
    is_ai_generated
  }
}
    `;
