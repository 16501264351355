import { datadogLogs } from '@datadog/browser-logs';
import { AiAutocompleteEntityOutput } from '@main/graphql/client-scalars';
import { useAiAutocompleteEntityMutation } from '@main/graphql/features/AiAutocompleteEntity.generated';
import { Client_Questionnaire_Question_Status_Enum } from '@main/graphql/types.generated';
import { isAbortError, toError, useStableCallback } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useUpdateClientQquestionAnswerMutation,
  useUpdateClientQquestionStatusMutation,
} from './use-question-service.generated';

export interface ClientQanswerUpdate {
  answer: string;
  comment: string;
}

export function useClientQquestionService() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const [updateStatus, { isLoading: isUpdatingStatus }] = useUpdateClientQquestionStatusMutation();
  const [updateAnswer, { isLoading: isUpdatingAnswer }] = useUpdateClientQquestionAnswerMutation();
  const [aiAutocompleteEntity, { isLoading: isAutocompleting }] = useAiAutocompleteEntityMutation();
  const isBusy = isUpdatingStatus || isUpdatingAnswer || isAutocompleting;

  const handleUpdate = useStableCallback(
    async (
      update: Promise<unknown>,
      successMsg: string | false = t('clientQuestionnaire.question.updateSuccessful'),
    ) => {
      try {
        await update;
        if (successMsg !== false) {
          successToast(successMsg);
        }
      } catch (error) {
        if (isAbortError(error)) {
          return;
        }

        errorToast(t('clientQuestionnaire.question.updateFailed'));
        datadogLogs.logger.error(
          'Failed to update Client Questionnaire Question',
          {},
          toError(error),
        );
      }
    },
  );

  const autocompleteAnswer = useStableCallback(async (questionId: string) => {
    try {
      const res = await aiAutocompleteEntity({
        input: { entity: 'clientq-question-answer', entityId: questionId },
      }).unwrap();
      const answer =
        res.ai_autocomplete_entity as AiAutocompleteEntityOutput<'clientq-question-answer'>;
      return { answer: answer.autocomplete, comment: answer.extra.comment } as ClientQanswerUpdate;
    } catch (error) {
      errorToast(t('clientQuestionnaire.question.autocomleteAnswerFailed'));
      datadogLogs.logger.error(
        'Failed to autocomplete Client Questionnaire Question Answer',
        { questionId },
        toError(error),
      );
      return;
    }
  });

  const acceptAutocompleteAnswer = useStableCallback(
    (questionId: string, userId: string, answer: ClientQanswerUpdate) =>
      handleUpdate(
        updateAnswer({
          questionId,
          userId,
          answer: answer.answer,
          comment: answer.comment,
          isAIGenerated: true,
        }).unwrap(),
      ),
  );

  const markAsApproved = useStableCallback(async (questionId: string) =>
    handleUpdate(
      updateStatus({ questionId, status: Client_Questionnaire_Question_Status_Enum.Approved }),
      t('clientQuestionnaire.question.approveSuccessful'),
    ),
  );

  const approveAnswer = useStableCallback((questionId: string, hasAnswer: boolean) =>
    !hasAnswer
      ? openDialog({
          dialogHeader: t('clientQquestionDrawer.alert.approve.header'),
          dialogContent: t('clientQquestionDrawer.alert.approve.content'),
          confirmAction: {
            children: t('clientQquestionDrawer.alert.approve.confirmLabel'),
            colorScheme: 'blue',
            onClick: () => markAsApproved(questionId),
          },
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        }).catch((e) => (isAbortError(e) ? undefined : Promise.reject(e)))
      : markAsApproved(questionId),
  );

  const shareLink = useStableCallback(async (questionId: string) => {
    try {
      const url = new URL(window.location.pathname, window.location.origin);
      url.searchParams.set('drawerEntity', 'client-q-question');
      url.searchParams.set('drawerEntityId', questionId);
      await navigator.clipboard.writeText(url.toString());
      successToast(t('clientQquestionDrawer.actions.share.success'));
    } catch (error) {
      errorToast(t('clientQquestionDrawer.actions.share.error'));
      datadogLogs.logger.error(
        'Failed to share Client Questionnaire Question link to clipboard',
        {},
        toError(error),
      );
    }
  });

  return useMemo(
    () => ({
      isBusy,
      updateStatus,
      updateAnswer,
      handleUpdate,
      autocompleteAnswer,
      acceptAutocompleteAnswer,
      approveAnswer,
      shareLink,
    }),
    [
      isBusy,
      updateStatus,
      updateAnswer,
      handleUpdate,
      autocompleteAnswer,
      acceptAutocompleteAnswer,
      approveAnswer,
      shareLink,
    ],
  );
}
