import { Finding_Types_Enum } from '@main/graphql/types.generated';

interface Finding {
  type: Finding_Types_Enum;
  ignored_at?: string;
}
export function deduplicateFindings<T extends Finding>(findings: T[]) {
  const uniqueFindings = [...new Set(findings.map((finding) => finding.type))];
  return findings.filter((finding) => uniqueFindings.includes(finding.type));
}
