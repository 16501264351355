import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCreateTaskOwnerMutation,
  useRemoveTaskOwnerMutation,
  useUpdateTaskByIdMutation,
} from './manage-tasks.generated';

export function useUpdateTaskHandler() {
  const { t } = useTranslation();

  return useCallback(
    async (
      resultPromise: ReturnType<
        ReturnType<
          | typeof useUpdateTaskByIdMutation
          | typeof useCreateTaskOwnerMutation
          | typeof useRemoveTaskOwnerMutation
        >[0]
      >,
    ) => {
      try {
        await resultPromise.unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.task') }));
        datadogLogs.logger.error('Task update failed', {}, toError(error));
      }
    },
    [t],
  );
}
