import { CommentsTab } from '../comments/comments-tab';
import { useGetClientQquestionCommentsTabQuery } from './question-comments-tab.generated';

export function QuestionCommentsTab({ questionId }: { questionId: string }) {
  const { data, refetch } = useGetClientQquestionCommentsTabQuery({ questionId });

  return (
    <CommentsTab
      entity="clientq-question"
      entityId={questionId}
      comments={data?.question?.comments ?? []}
      refetchComments={async () => void (await refetch().unwrap())}
    />
  );
}
